import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { message, Modal, Tooltip, Card } from 'antd';
import { sessionLogout, notifications, Escalations, remainder, Message, Loginvendor, LoginLitigation, LoginCompfieproduct } from './../Store/Action/Login';
import { useHistory, Link, useLocation } from "react-router-dom";
import { changeTheme } from '../Store/Action/Dashboard/header';
const _ = require("lodash");


const mapStateToProps = (state) => ({
    notify: state.Login.notifications,
    escalation: state.Login.escalations,
    remainderr: state.Login.remainder,
    messages: state.Login.message,
    loginvendor: state.Login.loginvendor,
    // theme : state.changeTheme.current_theme
});

const Header = ({
    sessionLogout,
    notifications,
    Escalations,
    changeTheme,
    notify,
    escalation,
    remainder, Message, remainderr, messages,
    Loginvendor,
    LoginLitigation,
    LoginCompfieproduct,
}) => {

    const isAuth = localStorage.getItem('isAuthenticated');
    const history = useHistory();
    const [entityinfo, Setentityinfo] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const vendorlogo = localStorage.getItem('isVendor')
    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    const entity_info = sessionValue.entity_info
    const country_info = sessionValue.country_info
    const [notificationdata, Setnotificationdata] = useState();
    const [escalationdata, Setescalationdata] = useState();
    const [reminderdata, Setreminderdata] = useState();
    const [formurl, Setformurl] = useState();
    const [settingurl, setSettingUrl] = useState()
    const [changepassword, Setchangepassword] = useState();
    const [messagedata, Setmessagedata] = useState();
    const [notificationValue, SetnotificationValue] = useState({
        remainderValue: '',
        statutoryNotificationValue: '',
        escalationValue: '',
        messagesValue: '',
    });
    const [notificationscount, Setnotificationscount] = useState();
    const authtoken = localStorage.getItem('authToken');
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const login = callername.login_response.menu.Static[0];
    console.log(login,"loginlogin");
    const getUrl = callername.login_response.menu['My Accounts']
    const [valueIn, setValueIn] = useState('');
    const location = useLocation();
    // const paramid = location.pathname;
    const [isModalVisibleVendor, setIsModalVisibleVendor] = useState(false);
    const [openThemeModalPopup, setOpenThemeModalPopup] = useState(false);
    const [closeTrue, setCloseTrue] = useState(false);
    const [paramid, setParamId] = useState(location.pathname)


    useEffect(() => {
        if (isAuth) {
            const getUrl = callername.login_response.menu['My Accounts']
            const remainderArray = _.filter(getUrl, { form_name: 'Reminders' })
            const statutoryArray = _.filter(getUrl, { form_name: 'Statutory Notifications' })
            const escalationArray = _.filter(getUrl, { form_name: 'Escalations' })
            const messageArray = _.filter(getUrl, { form_name: 'Messages' })
            const profile = _.filter(getUrl, { form_name: 'View Profile' })
            const ChangePassword = _.filter(getUrl, { form_name: 'Change Password' })
            const settings = _.filter(getUrl, { form_name: "Settings" })
            setSettingUrl(settings && settings.length > 0 && settings[0].form_url)
            Setformurl(profile[0].form_url)
            Setchangepassword(ChangePassword[0].form_url)
            SetnotificationValue({
                ...notificationValue,
                remainderValue: remainderArray && remainderArray.length > 0 && remainderArray[0].form_url,
                statutoryNotificationValue: statutoryArray && statutoryArray.length > 0 && statutoryArray[0].form_url,
                escalationValue: escalationArray && escalationArray.length > 0 && escalationArray[0].form_url,
                messagesValue: messageArray && messageArray.length > 0 && messageArray[0].form_url
            })
        }
    }, [isAuth])

   

    useEffect(() => {
        let notifypayload;
        let escalationpayload;
        let remainderpay;
        let messagepay;
        


        if (authtoken && legalid && legalid != "null") {
            notifypayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]

            escalationpayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 3,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]

            remainderpay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 2,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]

            messagepay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 4,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        }
        else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            notifypayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": temp,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]

            escalationpayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 3,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]

            remainderpay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 2,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]

            messagepay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 4,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        }
        notifications({
            payload: notifypayload,
            caller: login.form_url
        })
        Escalations({
            payload: escalationpayload,
            caller: login.form_url
        })
        remainder({
            payload: remainderpay,
            caller: login.form_url
        })
        Message({
            payload: messagepay,
            caller: login.form_url
        })

    }, [authtoken && legalid])

    useEffect(() => {
        let entity = localStorage.getItem('SelectedEntity');
        if (!entity && entity_info.length > 1) {
            setIsModalVisible(true)

        } else {
            if (!localStorage.getItem('SelectedEntity') && !localStorage.getItem('SelectedEntityid')) {
                entityselected(entity_info[0].le_name, entity_info[0].le_id)
                history.push('/dashboard');
                window.location.reload(false);
            }
        }
    }, [])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity')) {
            setCloseTrue(true);
        }
    })

    useEffect(() => {
        let entityvalue = localStorage.getItem('SelectedEntity');
        if (entityvalue) {
            Setentityinfo(entityvalue)
        }

    }, [entityinfo])

    const entityselection = () => {
        // localStorage.removeItem('SelectedEntity');
        // window.location.reload(false);
        setIsModalVisible(true)
    }

    //get unique array
    const getUnique = (arr, index) => {
        const unique = arr
            .map(e => e[index])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }

    const entity_count = entity_info.length
    const entity_sum = entity_info.reduce((total, entity_info) => total = total + entity_info.u_count, 0)

    useEffect(() => {
        let finalArray = [];
        let countrylistdata = [];
        let countrylisttempdata = [];
        let arr = [];
        entity_info && entity_info.length > 0 && entity_info.map(function (user) {
            if ((arr.indexOf(user.c_name) < 0))
                arr.push({ 'country_name': user.c_name, 'country_id': user.c_id });
            //    arr.push(user.c_name)
        });
        let newArray = getUnique(arr, 'country_id');
        for (let countrylist in newArray) {
            let country_id = newArray[countrylist].country_id
            let country_name = newArray[countrylist].country_name
            let entityArray = []
            let tempArray = []
            let sumValue = 0;
            for (let entitylist in entity_info) {
                let entitylist_cid = entity_info[entitylist].c_id
                if (entitylist_cid === country_id) {
                    sumValue = entity_info[entitylist].u_count + sumValue
                    entityArray = {
                        'bg_id': entity_info[entitylist].bg_id,
                        'bg_name': entity_info[entitylist].bg_name,
                        'c_id': entity_info[entitylist].c_id,
                        'c_name': entity_info[entitylist].c_name,
                        'le_id': entity_info[entitylist].le_id,
                        'le_name': entity_info[entitylist].le_name,
                        'u_count': entity_info[entitylist].u_count,
                    }
                    tempArray.push(entityArray)
                }
            }
            finalArray[country_name] = tempArray
            countrylistdata = {
                'c_id': country_id,
                'c_name': country_name,
                'allEntitySumNew': sumValue,
                'child': tempArray
            }
            countrylisttempdata.push(countrylistdata)
        }
        SetfinalResult({
            ...finalResult,
            Resultdata: countrylisttempdata
        })

    }, [])


    const entityselected = (c_name, c_id) => {
        localStorage.setItem('SelectedEntity', c_name);
        localStorage.setItem('SelectedEntityid', c_id);
        setIsModalVisible(false);
        window.location.reload(false);
    }

    const logout = () => {
        sessionLogout({})
    }



    const getnotifications = () => {
        let payload;
        if (legalid != 'null') {
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        }
        else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotifications",
                        {
                            "le_ids": temp,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        }
        notifications({
            payload: payload,
            caller: login.form_url
        })
    }

    const getescalations = () => {
        let payload;
        if (legalid != 'null') {
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 3,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 3,
                            "start_count": 0,
                            "end_count": 3
                        }
                    ]
                }
            ]
        }
        Escalations({
            payload: payload,
            caller: login.form_url
        })
    }
    const getreminders = () => {
        let payload;
        if (legalid != 'null') {
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 2,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 2,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        }
        remainder({
            payload: payload,
            caller: login.form_url
        })
    }

    const getmessages = () => {
        let messagepay;
        if (legalid != 'null') {
            messagepay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_type": 4,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            messagepay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotifications",
                        {
                            "le_ids": temp,
                            "notification_type": 4,
                            "start_count": 0,
                            "end_count": 2
                        }
                    ]
                }
            ]
        }
        Message({
            payload: messagepay,
            caller: login.form_url
        })
    }

    useEffect(() => {
        if (notify || escalation || remainderr || messages) {
            const data = notify.statutory;
            const es = escalation.escalations;
            const re = remainderr.reminders;
            const mes = messages.messages;
            Setnotificationdata(data);
            Setescalationdata(es);
            Setreminderdata(re);
            Setmessagedata(mes);
            localStorage.setItem('notificationscount', notify.statutory_count);
            localStorage.setItem('escalationcount', escalation.escalation_count);
            localStorage.setItem('remainderscount', remainderr.reminder_count);
            localStorage.setItem('messagecount', messages.messages_count)
        }

    }, [notify, escalation, remainderr, messages])
   
    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            if (the_arr.includes("dashboard")) {
                the_arr.pop();
                let a = the_arr.join('/')
                setParamId(a)
            } else {
                setParamId(location.pathname)
            }

        }
    }, [location]);

    const vendorLogin = () => {

        let loginvendorpayload;
        loginvendorpayload = [
            authtoken,
            [
                "LoginFormLess", {
                    "session_token": authtoken,
                    "is_logged_in_through_sso": false,
                    "product_id": "3"
                }
            ]
        ]

        Loginvendor({
            payload: loginvendorpayload,
            paramid: paramid,
        })

        localStorage.removeItem("SelectedEntity");
        localStorage.removeItem("SelectedEntityid");
    }

    const Compfieproduct = () => {

        let compfieproductpayload;
        compfieproductpayload = [
            authtoken,
            [
                "LoginFormLess", {
                    "session_token": authtoken,
                    "is_logged_in_through_sso": false,
                    "product_id": "1"
                }
            ]
        ]
        LoginCompfieproduct({
            payload: compfieproductpayload,
            paramid: paramid,
        })
        localStorage.removeItem("SelectedEntity");
        localStorage.removeItem("SelectedEntityid");

    }

    const LitigationLogin = () => {
        let loginlitipayload;
        loginlitipayload = [
            authtoken,
            [
                "LoginFormLess", {
                    "session_token": authtoken,
                    "is_logged_in_through_sso": false,
                    "product_id": "1"
                }
            ]
        ]
        LoginLitigation({
            payload: loginlitipayload,
            paramid: paramid,
        })

        localStorage.removeItem("SelectedEntity");
        localStorage.removeItem("SelectedEntityid");

    }



    const closepopup = () => {
        setIsModalVisible(false)
    }

    const closepopup1 = () => {
        setIsModalVisibleVendor(false)
    }

    const openThemeModal = () => {
        setOpenThemeModalPopup(true)
    }

    const closethememodalpopup1 = () => {
        setOpenThemeModalPopup(false)
    }

    const themes = (theme, color_param, skin_param) => {
        const payload = [
            authtoken,
            {
              "session_token": authtoken,
              "request": [
               "ChangeThemes",
               {
                "theme": theme
               }
              ]
            }
            ]
        changeTheme({
            payload: payload,
            paramid: paramid
        })
        localStorage.setItem('currentTheme', theme)
        localStorage.setItem('currentSkin', skin_param)
        localStorage.setItem('text-font', theme == 'theme_four' || theme == 'theme_one' ? 'text-dark' : 'text-white')
        localStorage.setItem('iconColor', theme == 'theme_four' || theme == 'theme_one' ? 'iconColorGrey':'iconColorWhite')
        
        window.location.reload()
    }

    const [notificationIconColor, setNotificationIconColor] = useState('point bg-primary')

    // const theme1 = () => {
    //     localStorage.setItem('currentTheme', 'blue')
    //     localStorage.setItem('currentSkin', 'skin2')
    //     localStorage.setItem('text-font', 'text-white')
    //     localStorage.setItem('iconColor', 'iconColorWhite')
    //     window.location.reload()
    // }

    // const theme2 = () => {
    //     localStorage.setItem('currentTheme', 'red')
    //     localStorage.setItem('currentSkin', 'skin3')
    //     localStorage.setItem('text-font', 'text-white')
    //     localStorage.setItem('iconColor', 'iconColorWhite')
    //     window.location.reload()
    // }

    // const theme3 = () => {
    //     localStorage.setItem('currentTheme', 'green')
    //     localStorage.setItem('currentSkin', 'skin4')
    //     localStorage.setItem('text-font', 'text-white')
    //     localStorage.setItem('iconColor', 'iconColorWhite')
    //     window.location.reload()
    // }
    // //default

    // const theme4 = () => {
    //     localStorage.setItem('currentTheme', 'default')
    //     localStorage.setItem('currentSkin', 'skin6')
    //     localStorage.setItem('text-font', 'text-dark')
    //     localStorage.setItem('iconColor', 'iconColorGrey')
    //     window.location.reload()
    // }
    // //default end
    // const theme5 = () => {
    //     localStorage.setItem('currentTheme', 'lightblue')
    //    `     localStorage.setItem('currentSkin', 'skin2')
    //         localStorage.setItem('text-font', 'text-white')
    //         localStorage.setItem('iconColor', 'iconColorWhite')`
    //     window.location.reload()
    // }

    useEffect(() => {
        if(localStorage.getItem('currentTheme') == 'theme_one'){
            setNotificationIconColor('point bg-primary')
        }else if(localStorage.getItem('currentTheme') == 'theme_two'){
            setNotificationIconColor('point bg-warning')
        }else if(localStorage.getItem('currentTheme') == 'theme_three'){
            setNotificationIconColor('point bg-error')
        }else if(localStorage.getItem('currentTheme') == 'theme_four'){
            setNotificationIconColor('point bg-error')
        }else{
            setNotificationIconColor('point bg-primary')
        }
    })

    const [sidebarbg, setBarBg] = useState('skin6')
    useEffect(() => {
        setBarBg(localStorage.getItem("currentSkin"))
    }, [localStorage.getItem("currentSkin")])

    return (
        <Fragment>
            <header className="topbar">
                <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                    <div className="navbar-header" style={{ 'overflow': 'hidden' }}>
                        <a
                            className="nav-toggler waves-effect waves-light d-block d-md-none"
                            href="/#"
                        ><i className="ri-close-line ri-menu-2-line fs-6"></i></a>
                        <div style={{ "height": "73px", 'overflow': 'hidden' }} className="row border-bottom ">
                            
                            <div className="col-md-2">
                                <a id="menu-icon" className="nav-link sidebartoggler d-none d-md-block"><i data-feather="menu"></i></a>
                                <a className="nav-link sidebartoggler d-md-block">
                                    <img id="star-icon" className="d-none staricon mt-2" src="favicon.png" alt=""></img>
                                </a>
                            </div>
                            {vendorlogo == "true" ? <>
                                <div id="vendor-logo" className="col-md-10 px-0">
                                    <Link className="navbar-brand  text-center vendor-logo">
                                        <img
                                            src="vendor-logo.png"
                                            alt="homepage"
                                            className="dark-logo w-75 mt-3 "
                                        />
                                    </Link>
                                </div> </> : <div id="logo-icon" className="col-md-10 px-0">
                                <Link className="navbar-brand  text-center logo-icon">
                                    <img src="logo-icon.png" alt="homepage"
                                        className="dark-logo w-75 mt-3 "
                                    />
                                </Link>
                            </div>}
                        </div>
                        <a
                            className="topbartoggler d-block d-md-none waves-effect waves-light"
                            href="/#"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        ><i className="ri-more-line fs-6"></i></a>
                    </div>
                    <div className="navbar-collapse collapse h-70" data-navbarbg={sidebarbg}>
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                {/* <!-- <a  id="navbarSupportedContent" // Div id
                  className="nav-link sidebartoggler d-none d-md-block"
                  href="#!"
                  ><i data-feather="menu"></i
                ></a> --> */}
                            </li>

                        </ul>

                        <ul className="navbar-nav d-flex align-items-center list-style-none mb-0">
                            {sessionValue.entity_info.length > 1 ? <Tooltip title='Click here to Change Legal Entity'><li className="nav-item dropdown"><div className={"nav-link "+ localStorage.getItem("text-font")} data-bs-toggle="modal"
                                data-bs-target="#bs-example-modal-xlg"><span className={localStorage.getItem("text-font")}>{entityinfo}</span>&nbsp;&nbsp;<i className={localStorage.getItem("text-font") +" ri-list-settings-line"} onClick={(e) => {
                                    entityselection()
                                }}></i></div></li></Tooltip> : <Tooltip title=''><li className="nav-item dropdown"><div className={"nav-link "+ localStorage.getItem("text-font")} data-bs-toggle="modal"
                                    data-bs-target="#bs-example-modal-xlg"><span className={localStorage.getItem("text-font")}>{entityinfo}</span>&nbsp;&nbsp;</div></li></Tooltip>}

                            <li className="nav-item dropdown mt-2">
                                <a className="nav-link dropdown-toggle"
                                    href="/#"
                                    id="2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={getmessages}
                                >
                                    <Tooltip title='Messages' getPopupContainer={(triggerNode) => {

                                        return triggerNode.parentNode;

                                    }}><div><i data-feather="message-square" className={localStorage.getItem('iconColor')}></i></div></Tooltip>
                                    <div className="notify">

                                        {
                                            localStorage.getItem('messagecount') != 0 ?
                                                <span className={notificationIconColor}></span> : null}
                                    </div>
                                </a>
                                <div
                                    className="dropdown-menu mailbox dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="2">
                                    <ul className="list-style-none">
                                        <li className='p-2'>
                                            <div className="h-50 message-center message-body position-relative ">
                                                {

                                                    messagedata && messagedata.length > 0 && messagedata.map((item, itemm) => {
                                                        return (<Link className="message-item d-flex align-items-center border-bottom" key={itemm}>
                                                            <div className=" d-inline-block v-middle ps-3 ms-1">
                                                                <Link to={notificationValue.messagesValue}><h5 className="message-title mb-0 mt-1 fs-4 font-weight-medium cut-text">{item.notification_text.replace(/['"]+/g, '')}</h5></Link>
                                                                <span className="fs-3 text-nowrap d-block time text-truncate fw-normal mt-1 text-muted">{item.created_on}</span>
                                                            </div>
                                                        </Link>)

                                                    })
                                                }
                                                {messagedata && messagedata.length > 0 ?
                                                    <div className="mt-2">
                                                        <Link
                                                            className="btn btn-info text-white"
                                                            to={notificationValue.messagesValue}
                                                        >
                                                            See all notifications
                                                        </Link>
                                                    </div>
                                                    : <div className="rounded-top p-2 text-center">
                                                        <h6 className="card-title mb-0">No Records Found</h6></div>
                                                }
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown mt-2">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={getnotifications}>
                                    <Tooltip title='Statutory Notifications' getPopupContainer={(triggerNode) => {

                                        return triggerNode.parentNode;

                                    }}><div><i data-feather="bell" className={localStorage.getItem('iconColor')} title='Notifications'></i></div></Tooltip>
                                    <div className="notify">
                                        {
                                            localStorage.getItem('notificationscount') != 0 ?
                                                <span className={notificationIconColor}></span> : null
                                        }
                                    </div>
                                </a>
                                <div
                                    className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up">

                                    <ul className="list-style-none">
                                        <li className='p-2'>
                                            <div className="h-50 message-center message-body position-relative ">
                                                {

                                                    notificationdata && notificationdata.length > 0 && notificationdata.map((item, itema) => {
                                                        return (<a href="javascript:void(0)" key={itema} className="message-item d-flex align-items-center border-bottom">
                                                            <div className=" d-inline-block v-middle ps-3 ms-1">
                                                                <Link to={notificationValue.statutoryNotificationValue}><h5 className="message-title mb-0 mt-1 fs-4 font-weight-medium cut-text">{item.notification_text.replace(/['"]+/g, '')}</h5></Link>
                                                                <span className="fs-3 text-nowrap d-block time text-truncate fw-normal mt-1 text-muted">{item.created_on}</span>
                                                            </div>
                                                        </a>)

                                                    })
                                                }
                                                {notificationdata && notificationdata.length > 0 ?
                                                    <div className="mt-2">
                                                        <Link
                                                            className="btn btn-info text-white"
                                                            to={notificationValue.statutoryNotificationValue}
                                                        >
                                                            See all notifications
                                                        </Link>
                                                    </div>
                                                    : <div className="rounded-top p-2 text-center">
                                                        <h6 className="card-title mb-0">No Records Found</h6></div>
                                                }
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown mt-2">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={getescalations}>
                                    <Tooltip title='Escalations' getPopupContainer={(triggerNode) => {

                                        return triggerNode.parentNode;

                                    }}><div><i data-feather="zap" className={localStorage.getItem('iconColor')}></i></div></Tooltip>


                                    <div className="notify">
                                        {
                                            localStorage.getItem('escalationcount') != 0 ?
                                                <span className={notificationIconColor}></span> : null}
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up">
                                    <ul className="list-style-none">
                                        <li className='p-2'>
                                            <div className="h-50 message-center message-body position-relative ">
                                                {

                                                    escalationdata && escalationdata.length > 0 && escalationdata.map((item, itemj) => {
                                                        return (<Link className="message-item d-flex align-items-center border-bottom" key={itemj}>
                                                            <div className=" d-inline-block v-middle ps-3 ms-1">
                                                                <Link to={notificationValue.escalationValue}><h5 className="message-title mb-0 mt-1 fs-4 font-weight-medium cut-text">{item.notification_text.replace(/['"]+/g, '')}</h5></Link>
                                                                <span className="fs-3 text-nowrap d-block time text-truncate fw-normal mt-1 text-muted">{item.created_on}</span>
                                                            </div>
                                                        </Link>)

                                                    })
                                                }
                                                {escalationdata && escalationdata.length > 0 ?
                                                    <div className="mt-2">
                                                        <Link
                                                            className="btn btn-info text-white"
                                                            to={notificationValue.escalationValue}

                                                        >
                                                            See all notifications
                                                        </Link>
                                                    </div>
                                                    : <div className="rounded-top p-2 text-center">
                                                        <h6 className="card-title mb-0">No Records Found</h6></div>
                                                }
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown mt-2">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={getreminders}>
                                    <Tooltip title='Reminders' getPopupContainer={(triggerNode) => {

                                        return triggerNode.parentNode;

                                    }}><div><i data-feather="clock" className={localStorage.getItem('iconColor')}></i></div></Tooltip>
                                    <div className="notify">
                                        {
                                            localStorage.getItem('remainderscount') != 0 ?
                                                <span className={notificationIconColor}></span> : null}
                                    </div>
                                </a>
                                <div style={{ 'overflow': 'hidden' }} className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up pb-0">
                                    <ul className="list-style-none">
                                        <li className='p-2'>
                                            <div className="h-50 message-center message-body position-relative ">
                                                {

                                                    reminderdata && reminderdata.length > 0 && reminderdata.map((item, itemk) => {
                                                        return (<Link className="message-item d-flex align-items-center border-bottom" key={itemk}>
                                                            <div className=" d-inline-block v-middle ps-3 ms-1">
                                                                <Link to={notificationValue.remainderValue}><h5 className="message-title mb-0 mt-1 fs-4 font-weight-medium cut-text">{item.notification_text.replace(/['"]+/g, '')}</h5></Link>
                                                                <span className="fs-3 text-nowrap d-block time text-truncate fw-normal mt-1 text-muted">{item.created_on}</span>
                                                            </div>
                                                        </Link>)

                                                    })
                                                }
                                                {reminderdata && reminderdata.length > 0 ?
                                                    <div className="mt-2">
                                                        <Link
                                                            className="btn btn-info text-white"
                                                            to={notificationValue.remainderValue}

                                                        >
                                                            See all notifications
                                                        </Link>
                                                    </div>
                                                    : <div className="rounded-top p-2 text-center">
                                                        <h6 className="card-title mb-0">No Records Found</h6></div>
                                                }
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown profile-dropdown">
                                <a
                                    className="nav-link dropdown-toggle d-flex align-items-center"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img
                                        src="3.png"
                                        alt="user"
                                        width="30"
                                        className="profile-pic rounded-circle"
                                    />
                                    <div className="d-none d-md-flex align-items-center">
                                        <span className="ms-2"
                                        >
                                            <span className={localStorage.getItem("text-font") + " fw-bold"}>{sessionValue.login_response.emp_name}</span>

                                        </span>
                                        <span className='mt-1'>
                                            <i data-feather="chevron-down" className={localStorage.getItem('iconColor')}></i>
                                        </span>
                                    </div>

                                </a>
                                <div
                                    className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up">
                                    <ul className="list-style-none">
                                        <li className="p-3 pb-2 pt-3">
                                            <div className="rounded-top d-flex align-items-center">
                                                <h3 className="card-title mb-0 fs-4">My Accounts</h3>
                                                <div className="ms-auto">
                                                    <Link className="link py-0">
                                                        <i data-feather="x-circle"></i>
                                                    </Link>
                                                </div>
                                            </div>

                                        </li>
                                        <li id='myAccounts' className="p-3 pb-2 pt-0 ml-2 position-relative" style={{ "height": "200px", 'overflowY': 'scroll' }}>
                                            <div className="message-body position-relative"
                                            >
                                                <Link to={formurl ? formurl : ''}
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span className="btn btn-light-info btn-rounded-lg text-info p-2">
                                                        <i data-feather="user"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">

                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            View Profile
                                                        </h5>
                                                    </div>
                                                </Link>
                                                <Link to={changepassword ? changepassword : ''}
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span className="btn btn-light-info btn-rounded-lg text-info p-2">
                                                        <i
                                                            data-feather="lock"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">

                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            Change Password
                                                        </h5>
                                                    </div>
                                                </Link>
                                                <Link to={settingurl ? settingurl : ''}
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span className="btn btn-light-info btn-rounded-lg text-info p-2">
                                                        <i
                                                            data-feather="settings"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">
                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            Settings
                                                        </h5>
                                                    </div>
                                                </Link>

                                                <a
                                                    href="/#"
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span
                                                        className="btn btn-light-info btn-rounded-lg text-info p-2"
                                                    >
                                                        <i
                                                            data-feather="help-circle"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">


                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            User Guide
                                                        </h5>

                                                    </div>
                                                </a>

                                                <Link onClick={(e) => {
                                                    openThemeModal()
                                                }}
                                                    className="message-item px-2 d-flex align-items-center border-bottom py-1">
                                                    <span
                                                        className="btn btn-light-info btn-rounded-lg text-info p-2"
                                                    >
                                                        <i
                                                            data-feather="copy"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">
                                                        <h5 className="message-title mb-0 mt-1 fs-3 font-weight-medium">
                                                            Themes
                                                        </h5>


                                                    </div>
                                                </Link>

                                            </div>

                                        </li>
                                        <li className='my-0 mx-2'>
                                            <div className="mt-2">
                                                <a
                                                    className="btn btn-info text-white"
                                                    onClick={logout}
                                                >
                                                    Logout
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <Tooltip title='Click here for Products' placement='left'><div><i className={localStorage.getItem('iconColor') + " bi-grid-3x3-gap-fill"}></i></div></Tooltip>
                                    {/* <Tooltip title='Click here for Products'><div><i className="bi-grid-3x3-gap-fill text-primary"></i></div></Tooltip> */}
                                    <div >
                                        <span className={notificationIconColor}></span>
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up w-75">
                                    <ul className="list-style-none">

                                        <li className="p-3 pt-0 pb-0 mt-3">
                                            <div className="message-center message-body position-relative"
                                                style={{ "height": "100%" }}
                                            >
                                                <Link onClick={(e) => { Compfieproduct() }}
                                                    href="/#" className="message-item px-1 d-flex align-items-center border-bottom py-2">
                                                    <img src="logo-icon.png" style={{ width: "75px", marginLeft: '5px' }} alt="" />
                                                </Link>
                                                <Link onClick={(e) => { LitigationLogin() }}
                                                    href="/#" className="message-item px-1 d-flex align-items-center border-bottom py-2">
                                                    <img src="litigation-logo.png" style={{ width: "140px" }} alt="" />
                                                </Link>
                                                <Link onClick={(e) => { vendorLogin() }}
                                                    className="message-item px-1 d-flex align-items-center border-bottom py-2">
                                                    <img src="vendor-logo.png" style={{ width: "115px" }} alt="" />
                                                </Link>

                                            </div>

                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav >
            </header >

            <Modal visible={isModalVisible} maskStyle={{ backdropFilter: 'blur(15px)' }} footer={false} onCancel={closepopup} closable={closeTrue} keyboard={false} maskClosable={false} style={{ padding: 0 }}>
                <div className="accordion" id="accordionExample" style={{ marginTop: "15px", padding: 0 }}>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="btn btn-primary w-100 rounded-0"
                                type="button"
                            >
                                <div className="row w-100 text-start rounded-0 p-2">
                                    <div className="col-6" style={{ textAlign: "center" }}>
                                        Select Legal Entity
                                    </div>
                                    <div className="col-3" style={{ textAlign: "center" }}>
                                        Legal Entity Count
                                    </div>
                                    <div className="col " style={{ textAlign: "center" }}>
                                        Unit Count
                                    </div>
                                </div>
                            </button>
                        </h2>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" >
                            <button
                                className="accordion-button collapsed custo"
                                type="button"
                            >
                                <div className="row w-100 text-start rounded-0 p-2">
                                    <div className="col-6" style={{ textAlign: "left" }}
                                        onClick={() => {
                                            localStorage.setItem('SelectedEntity', "All Legal Entity")
                                            localStorage.setItem('SelectedEntityid', "null")
                                            setIsModalVisible(false);
                                            if (localStorage.getItem('isVendor') == false) {
                                                history.push('/page3');
                                            } else {
                                                history.push('/page3');
                                            }
                                            window.location.reload(false);
                                        }}>
                                        <label>  All Legal Entities</label>
                                    </div>
                                    <div className="col-3" style={{ textAlign: "center" }}>
                                        <label style={{ textAlign: "center" }}> {entity_count}</label>
                                    </div>
                                    <div className="col " style={{ textAlign: "center" }}>
                                        <label style={{ textAlign: "center" }}> {entity_sum}</label>
                                    </div>
                                </div>
                            </button>
                        </h2>

                    </div>

                    {finalResult.Resultdata.length > 0 &&
                        finalResult.Resultdata.map((finalList, n) => (
                            <div className="accordion-item" key={n}>
                                {finalList.c_id == 1 ?
                                    <>
                                        <h2 className="accordion-header" id="headingOne">

                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target={"#collapse" + finalList.c_id}
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                                style={{ backgroundColor: '#e8f5ff' }}
                                            >
                                                <div className="row w-100 text-start rounded-0 p-2">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        {finalList.c_name}
                                                    </div>
                                                    <div className="col-3" style={{ textAlign: "center" }}>
                                                        {finalList.child.length}
                                                    </div>
                                                    <div className="col" style={{ textAlign: "center" }}>
                                                        {finalList.allEntitySumNew}
                                                    </div>
                                                </div>
                                            </button>

                                        </h2>

                                        <div
                                            id={"collapse" + finalList.c_id}
                                            className="accordion-collapse collapse in"
                                            aria-labelledby="headingOne"
                                            data-parent="#accordionExample">
                                            <div className="accordion-body overflow-hidden px-0 py-0">
                                                {finalList.child.length > 0 &&
                                                    finalList.child.map((childList, l) => (
                                                        <div className="row border-bottom p-4 py-3" key={l}>
                                                            <div className="col-6">
                                                                <a data-bs-dismiss="modal" className="cur-point" onClick={(e) => {
                                                                    entityselected(childList.le_name, childList.le_id)
                                                                    // history.push('/dashboard');
                                                                    history.push(callername.login_response.menu.Static[0].form_url);

                                                                    window.location.reload(false);
                                                                }}>{childList.le_name}</a>
                                                            </div>
                                                            <div className="col-3" style={{ textAlign: "left" }}>

                                                            </div>
                                                            <div className="col" style={{ textAlign: "left", paddingLeft: '3.7rem' }}>
                                                                {childList.u_count}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </>
                                    : <><h2 className="accordion-header" id="headingOne">

                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target={"#collapse" + finalList.c_id}
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            <div className="row w-100 text-start rounded-0 p-2">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    {finalList.c_name}
                                                </div>
                                                <div className="col-3" style={{ textAlign: "center" }}>
                                                    {finalList.child.length}
                                                </div>
                                                <div className="col" style={{ textAlign: "center" }}>
                                                    {finalList.allEntitySumNew}
                                                </div>
                                            </div>
                                        </button>

                                    </h2>

                                        <div
                                            id={"collapse" + finalList.c_id}
                                            className="accordion-collapse collapse"
                                            aria-labelledby="headingOne"
                                            data-parent="#accordionExample">
                                            <div className="accordion-body overflow-hidden px-0 py-0">
                                                {finalList.child.length > 0 &&
                                                    finalList.child.map((childList, m) => (
                                                        <div className="row border-bottom p-4 py-3" key={m}>
                                                            <div className="col-6">
                                                                <a data-bs-dismiss="modal" className="cur-point" onClick={(e) => {
                                                                    entityselected(childList.le_name, childList.le_id)
                                                                    history.push('/dashboard');
                                                                    window.location.reload(false);
                                                                }}>{childList.le_name}</a>
                                                            </div>
                                                            <div className="col-3" style={{ textAlign: "left" }}>

                                                            </div>
                                                            <div className="col" style={{ textAlign: "left", paddingLeft: '3.7rem' }}>
                                                                {childList.u_count}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </>}
                            </div>
                        ))}

                </div>

            </Modal>

            <Modal visible={isModalVisibleVendor} maskStyle={{ backdropFilter: 'blur(15px)' }} footer={false} onCancel={closepopup1} closable={closeTrue} keyboard={false} maskClosable={false} style={{ padding: 0 }}>
                <div className="accordion" id="accordionExample" style={{ marginTop: "15px", padding: 0 }}>
                    <div className="accordion-item">
                        <h2 className="accordion-header"   >
                            <button

                                className="btn btn-primary w-100 rounded-0"
                                type="button"

                            >
                                <div className="row w-100 text-start rounded-0 p-2">
                                    <div className="col-6" style={{ textAlign: "center" }}>
                                        Select Legal Entity
                                    </div>
                                    <div className="col-3" style={{ textAlign: "center" }}>
                                        Legal Entity Count
                                    </div>
                                    <div className="col " style={{ textAlign: "center" }}>
                                        Unit Count
                                    </div>
                                </div>
                            </button>
                        </h2>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" >
                            <button
                                className="accordion-button collapsed custo"
                                type="button"
                            >
                                <div className="row w-100 text-start rounded-0 p-2">
                                    <div className="col-6" style={{ textAlign: "left" }}
                                        onClick={() => {
                                            localStorage.setItem('SelectedEntity', "All Legal Entity")
                                            localStorage.setItem('SelectedEntityid', "null")
                                            setIsModalVisible(false);
                                            console.log(callername.login_response.menu.Static[0].form_url,"callernameeeee");
                                            history.push(callername.login_response.menu.Static[0].form_url);
                                            window.location.reload(false);
                                        }}>
                                        <label>  All Legal Entities</label>
                                    </div>
                                    <div className="col-3" style={{ textAlign: "center" }}>
                                        <label style={{ textAlign: "center" }}> {entity_count}</label>
                                    </div>
                                    <div className="col " style={{ textAlign: "center" }}>
                                        <label style={{ textAlign: "center" }}> {entity_sum}</label>
                                    </div>
                                </div>
                            </button>
                        </h2>

                    </div>

                    {finalResult.Resultdata.length > 0 &&
                        finalResult.Resultdata.map((finalList, a) => (
                            <div className="accordion-item" key={a}>
                                {finalList.c_id == 1 ?
                                    <>
                                        <h2 className="accordion-header" id="headingOne">

                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target={"#collapse" + finalList.c_id}
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                                style={{ backgroundColor: '#e8f5ff' }}
                                            >
                                                <div className="row w-100 text-start rounded-0 p-2">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        {finalList.c_name}
                                                    </div>
                                                    <div className="col-3" style={{ textAlign: "center" }}>
                                                        {finalList.child.length}
                                                    </div>
                                                    <div className="col" style={{ textAlign: "center" }}>
                                                        {finalList.allEntitySumNew}
                                                    </div>
                                                </div>
                                            </button>

                                        </h2>

                                        <div
                                            id={"collapse" + finalList.c_id}
                                            className="accordion-collapse collapse in"
                                            aria-labelledby="headingOne"
                                            data-parent="#accordionExample">
                                            <div className="accordion-body py-0">
                                                {finalList.child.length > 0 &&
                                                    finalList.child.map((childList, g) => (
                                                        <div className="row border-bottom pb-3 pt-2" key={g}>
                                                            <div className="col-6">
                                                                <a data-bs-dismiss="modal" className="cur-point" onClick={(e) => {
                                                                    entityselected(childList.le_name, childList.le_id)
                                                                    history.push('/dashboard');
                                                                    window.location.reload(false);
                                                                }}>{childList.le_name}</a>
                                                            </div>
                                                            <div className="col-3" style={{ textAlign: "center" }}>

                                                            </div>
                                                            <div className="col" style={{ paddingLeft: "55px" }}>
                                                                {childList.u_count}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </>
                                    : <><h2 className="accordion-header" id="headingOne">

                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target={"#collapse" + finalList.c_id}
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            <div className="row w-100 text-start rounded-0 p-2">
                                                <div className="col" style={{ textAlign: "left" }}>
                                                    {finalList.c_name}
                                                </div>
                                                <div className="col" style={{ textAlign: "center" }}>
                                                    {finalList.child.length}
                                                </div>
                                                <div className="col" style={{ textAlign: "center" }}>
                                                    {finalList.allEntitySumNew}
                                                </div>
                                            </div>
                                        </button>

                                    </h2>

                                        <div
                                            id={"collapse" + finalList.c_id}
                                            className="accordion-collapse collapse"
                                            aria-labelledby="headingOne"
                                            data-parent="#accordionExample">
                                            <div className="accordion-body py-0">
                                                {finalList.child.length > 0 &&
                                                    finalList.child.map((childList, b) => (
                                                        <div className="row w-100 pb-2" key={b}>
                                                            <div className="col">
                                                                <a data-bs-dismiss="modal" className="cur-point" onClick={(e) => {
                                                                    entityselected(childList.le_name, childList.le_id)
                                                                    history.push('/dashboard');
                                                                    window.location.reload(false);
                                                                }}>{childList.le_name}</a>
                                                            </div>
                                                            <div className="col" style={{ textAlign: "center" }}>

                                                            </div>
                                                            <div className="col" style={{ textAlign: "center" }}>
                                                                {childList.u_count}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </>}
                            </div>
                        ))}

                </div>

            </Modal>

            <Modal title={'Themes'} visible={openThemeModalPopup} maskStyle={{ backdropFilter: 'blur(15px)' }} footer={false} onCancel={closethememodalpopup1} closable={closeTrue} keyboard={false} maskClosable={false} style={{ padding: 0 }}>
                <Card>
                    <div className='row'>
                        <div className='col-md-2'>
                            <Link>Theme One</Link>
                            {/* <i style={{ color: "blue" }} class="bi bi-square-fill"></i> */}
                        </div>
                        <div className='col-md-2'>
                            <Link>Theme Two</Link>
                        </div>
                        <div className='col-md-2'>
                            <Link>Theme Three</Link>
                        </div>
                        <div className='col-md-2'>
                            <Link>Theme Four</Link>
                        </div>                        
                    </div>
                    <div className='row'>
                        <div className='col-md-2' style={{ paddingLeft: "35px" }}>                           
                            <i style={{ color: "#1e4db7" }} className="bi bi-square-fill" onClick={(e) => {
                                themes('theme_one','default','skin6')
                                // themes('theme_one','blue','skin2')
                            }}></i>
                        </div>
                        <div className='col-md-2' style={{ paddingLeft: "35px" }}>                          
                            <i style={{ color: "#595F72" }} className="bi bi-square-fill" onClick={(e) => {
                                themes('theme_two','red','skin7')
                            }}></i>
                        </div>
                        <div className='col-md-2' style={{ paddingLeft: "35px" }}>                        
                            <i style={{ color: "#650887" }} className="bi bi-square-fill" onClick={(e) => {
                               themes('theme_three','green','skin8')
                            }}></i>
                        </div>
                        <div className='col-md-2' style={{ paddingLeft: "35px" }}>                            
                            <i style={{ color: "#FFC700" }} className="bi bi-square-fill" onClick={(e) => {
                                themes('theme_four','lightblue','skin9')
                            }}></i>
                        </div>
                        {/* <div className='col-md-2' style={{ paddingLeft: "50px" }}>
                            <i style={{ color: "default" }} className="bi bi-square-fill" onClick={(e) => {
                                themes('theme_default','default','skin6')
                            }}></i>
                        </div> */}
                    </div>                    
                </Card>
            </Modal>

        </Fragment >
    )
};


export default connect(mapStateToProps, {
    sessionLogout,
    notifications,
    Escalations,
    remainder, Message,
    Loginvendor,
    LoginLitigation,
    LoginCompfieproduct,
    changeTheme
})(Header);