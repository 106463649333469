import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Modal, Input, Table, Tooltip, Button } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { remarkslist, remarksupdate } from './../../Store/Action/Master/Remarksmaster';
import { Toaster } from '../../Libs/Toaster';
import { PlayCircleOutlined } from '@ant-design/icons';


const mapStateToProps = (state) => ({
    lists: state.Remarksmaster.remarksmasters

})
const Remarksmaster = ({
    remarkslist,
    lists,
    remarksupdate
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const [pageSize, setPageSize] = useState(10)
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [current, setCurrent] = useState(1);
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    console.log(AddFormSubmit, 'addd');
    const [modalVisible, setModaVisible] = useState(false)
    const [remarkValue, setRemarkValue] = useState({
        id: '',
        description: '',
        desc: ''
    })
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const { Search } = Input;
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');

    const handleOk = () => {
        setAddFormSubmit(true)
        if (formValidator.current.allValid()) {
            if (remarkValue.desc == remarkValue.description) {
                Toaster.warning('No Changes Done');
                setModaVisible(false)
            } else {
                const updateremarks = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateRemarksMaster",
                            {
                                "r_id": remarkValue.id,
                                "r_description": remarkValue.description,
                            }
                        ]
                    }
                ]
                remarksupdate({
                    payload: updateremarks,
                    paramid: paramid,
                    authtoken: authtoken
                })
                setModaVisible(false)
            }
        }
    }
    const handleCancel = () => {
        setModaVisible(false)
    }
    const openModal = (r_id, description) => {
        setRemarkValue({
            ...remarkValue,
            id: r_id,
            description: description,
            desc: description
        })
        setModaVisible(true)
    }

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])


    const columns = [
        {
            title: '#',
            dataIndex: 'r_id',
            key: 'r_id',
            align: 'center',
            width: '12px',

        },
        {
            title: 'Remarks',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '150px',
        },
        {
            title: "Edit",
            dataIndex: 'u_c_name',
            align: 'center',
            width: '15px',
            render: (text, record) => {
                return (
                    <Link className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center margin0" onClick={(e) => {
                            openModal(record.r_id, record.r_description)
                            setAddFormSubmit(false)
                        }}>
                        <Tooltip title="Click here to Edit" color='green'>
                            <i className="ri-edit-line"></i></Tooltip>
                    </Link>

                )
            },
            sortable: false,
        },
    ];

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetRemarksData",
                        {

                        }
                    ]
                }
            ]

            remarkslist({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    const FilterComponent = ({ filterText, onFilter, onClear }) => {
        return (
            <>

                <div className='row'>
                    <div className="col-md-12">
                        <Input
                            type="text"
                            placeholder="Search Here..."
                            value={filterText}
                            onChange={onFilter}
                            autoFocus
                        />
                    </div>
                </div>

            </>
        )
    }

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <>
                <FilterComponent
                    onFilter={(e) => {
                        setFilterText(e.target.value)
                    }}
                    onClear={handleClear}
                    filterText={filterText}
                />

            </>

        );
    }, [filterText, resetPaginationToggle]);

    const filteredItems = lists && lists.length && lists.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const [baseData, setBaseData] = useState([]);
    console.log(baseData, 'baseData')
    // const [commonSearch, setCommonSearch] = useState();
    useEffect(() => {
        if (lists && lists.length > 0) {
            setBaseData(lists);
        }
    }, [lists])


    const searchRecords = (e) => {
        if (e == 'clear') {
            setClearText('')
            setBaseData(lists)
            setIconEnable(false)
        } else {
            setClearText(e.target.value)
            if (e.target.value.length > 0) {
                setIconEnable(true)
            } else {
                setIconEnable(false)
            }
            const filterTable = lists.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
            setBaseData(filterTable)
        }

    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const onInputChange = e => {
        const { value } = e.target;


        const re = /^[A-Z0-9a-z _ .,-]+$/;
        if (value === "" || re.test(value)) {
            setRemarkValue({
                ...remarkValue,
                description: e.target.value
            })
        }
    }

    return (
        <Fragment>
            <div>

                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span style={{ color: '#1890ff' }}>Remarks Master</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                                <div className="search-box" style={{ right:'16.5%',marginRight:'0',marginTop:'5px'}}>
                                    <input className="search-txt" value={clearText} type="text" onChange={searchRecords} placeholder="Type to Search" />
                                    <a className="search-btn" >
                                        {iconEnable == true ?
                                            <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                            : <i className="fas fa-search"></i>}
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <center>
                                    <div className="card bg-transparent border-0" style={{ maxWidth: '70%' }}>
                                        <div className="card-body" style={{ padding: '0px' }}>

                                            <Table
                                                // id='only-restricted'
                                                style={{ padding: '5px !important' }}
                                                // className='remarkclassnew '
                                                className={localStorage.getItem("currentTheme") + ' remarkclassnew'}
                                                size={'small'}
                                                columns={columns}
                                                dataSource={baseData}
                                                bordered
                                                pagination={false}
                                                scroll={{ y: 1000 }}

                                            />

                                        </div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>

                </div >

            </div>
            <Modal visible={modalVisible} footer={null} maskClosable={false} onCancel={handleCancel} className="newStyleModalPassword-remarks" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"> <b>Remarks </b><span style={{ "color": "red" }}>*</span></label>
                            <textarea className='form-control' name='remark' value={remarkValue.description}
                                row='1' cols='4' style={{ resize: 'none', height: '150px' }} maxLength={500} onChange={onInputChange}
                            >
                            </textarea>

                            {formValidator.current.message(
                                'remark',
                                remarkValue.description,
                                ['required', { regex: /^[A-Za-z0-9_ .,-]{0,500}$/ }],

                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Remarks Required',
                                        regex: '500 Charachers only allowed'
                                    }
                                })}
                        </div>
                    </div>
                </div>
                <div className="form-actions text-center popupbtncolour py-3">
                    <Button type="primary" shape="round" className={'addbutton '+ localStorage.getItem('currentTheme')}
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '-4%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={handleOk}
                    >Submit
                    </Button>
                     {/* <div class="button2" id={localStorage.getItem('currentTheme')} onClick={handleOk}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Submit
                                        </div> */}
                </div>

            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    remarkslist,
    remarksupdate
})(Remarksmaster);