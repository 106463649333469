import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Collapse, Input, Modal, Tooltip, Card, Select, Button, Table, Space, DatePicker } from 'antd';
import { InfoCircleOutlined, PlusSquareTwoTone, PlayCircleOutlined, SearchOutlined, FilterFilled, PlusOutlined, DownloadOutlined, ExclamationCircleTwoTone, CloseOutlined, PlusCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { complianceApprovallist, getdownloadpath, getremarkhistory, getComplianceFilters, approveCompliance, UploadFile, Downloadfile, removedocument } from '../../Store/Action/Transcations/ComplianceApproval';
import { GetRemarksData } from '../../Store/Action/Transcations/Taskrecall';
import { useLocation, Link } from 'react-router-dom';
import DataTable from "react-data-table-component";
import moment from 'moment';
import EncryptDecrypt from './../../../src/Libs/Encrypt&Decrypt'
import SimpleReactValidator from "simple-react-validator";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';


const _ = require("lodash");

const mapStateToProps = (state) => ({
    list: state.ComplianceApproval.complianceApproval.complianceList,
    frequencyList: state.ComplianceApproval.complianceApproval.complianceFrequencyList,
    unitList: state.ComplianceApproval.complianceApproval.complianceUnitList,
    domainList: state.ComplianceApproval.complianceApproval.complianceDomainList,
    userList: state.ComplianceApproval.complianceApproval.complianceUserList,
    filterrecall: state.recallfilters.recallfilters,
    nextRecords: state.ComplianceApproval.complianceApproval.nextrecord,
    fileremove: state.ComplianceApproval.complianceApproval.removefile,
    remarklist: state.ComplianceApproval.complianceApproval.remarkmodaldata,

})

const ComplianceApproval = ({
    getdownloadpath,
    getremarkhistory,
    complianceApprovallist,
    getComplianceFilters,
    approveCompliance,
    GetRemarksData,
    UploadFile,
    list,
    frequencyList,
    nextRecords,
    unitList,
    domainList,
    userList,
    Downloadfile,
    removedocument,
    fileremove,
    filterrecall: { recallremarks },
    remarklist

}) => {
    const [tooltip, setToolTip] = useState([])
    const { Panel } = Collapse;
    const { Search } = Input;
    const { Option } = Select;
    const { TextArea } = Input;
    const currentDate = new Date().toJSON().slice(0, 10);
    console.log(currentDate, "today111");
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    //   const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const countryidd = sessionParsedValue.country_info[0].c_id
    const currentdate = new Date();
    const [listArray, setListArray] = useState(false)
    const [remarksModal, setRemarksModal] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const formvalidator = useRef(new SimpleReactValidator);
    const [index, setIndex] = useState()
    console.log(index, 'snehaaaaaa');
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [passvisible, Setpassvisible] = useState(false);
    const [showFilterData, setShowFilterData] = useState({
        le_id: '',
        unit_id: null,
        unit_name: '',
        d_id: null,
        domain_name: '',
        frequency_id: null,
        frequency_name: '',
        us_id: null,
        user_name: '',
        start_count: 0
    })
    console.log(list, 'Table data LIST')
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const [page, setPage] = useState(1);
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [showValueModal, setShowValueModal] = useState(false)
    const [valueModal, setValueModal] = useState({
        unit: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: [],
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: '',
        description: "",
        completion_date: "",
        unit_id: null,
        approve_status: "",
        actiondata: "",
        start_date: "",
        domain_id: null,
        history_count: '',
        occurrence_remarks: '',
    })
    console.log(valueModal.validity_date, "next_due_date");
    console.log(moment(valueModal.next_due_date).format("DD-MMM-YYYY"), "1111111");
    console.log(moment(currentDate).format("DD-MMM-YYYY"), "2222222222");
    console.log(moment(valueModal.next_due_date).format("DD-MMM-YYYY") < moment(currentDate).format("DD-MMM-YYYY"), "3333333333")
    console.log(moment(valueModal.next_due_date) < moment(currentDate).format("DD-MMM-YYYY"), "000000000999999");
    const [password, setpassword] = useState({
        passvalue: ''
    });
    const [valueForFileUpload, setValueForFileUpload] = useState({
        d_id: '',
        u_id: '',
        start_date: '',
        file_info: [],
    })
    const [edit, setEdit] = useState(false)
    const [fileData, setFileData] = useState([])

    const [fileupload, setFileUpload] = useState([])

    const [tabValue, setTabvalue] = useState(false);
    const [remarksvalue, Setremarksvalue] = useState();
    const [entityid, setCurrentEntity] = useState("");
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [isModalOpenremark, setIsModalOpenremark] = useState(false);
    const showModalremark = () => {
        setIsModalOpenremark(true);
    };
    const handleCancelremark = () => {
        setIsModalOpenremark(false);
    };
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        console.log(dataIndex, 'SEARCH VALUE')
        setSearchText(e.target.value);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const [defaultRemarks, setDefaultRemarks] = useState();


    const getColumnSearchProps = (dataIndex,placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div>
                <Input
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    console.log(remarklist, 'remarklist');
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    console.log(nextRecords, 'nextRecords55555');
    useEffect(() => {
        if (nextRecords.length > 0) {
            setValueModal({
                compliance_history_id: nextRecords && nextRecords[0].compliance_history_id,
                unit: nextRecords && nextRecords[0].unit_name,
                compliance_task: nextRecords && nextRecords[0].compliance_name,
                remarks: nextRecords && nextRecords[0].remarks,
                compliance_frequency: nextRecords && nextRecords[0].compliance_task_frequency,
                document_issued_date: nextRecords && nextRecords[0].completion_date,
                document_reference_number: nextRecords && nextRecords[0].doc_ref_num,
                next_due_date: nextRecords && nextRecords[0].next_due_date != null ? nextRecords && nextRecords[0].next_due_date : nextRecords && nextRecords.due_date
            })
            setValueForFileUpload({
                d_id: nextRecords && nextRecords[0].domain_id,
                u_id: nextRecords && nextRecords[0].unit_id,
                start_date: nextRecords && nextRecords[0].start_date,
            })
            showValue()
        }

    }, [nextRecords])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            if (entityid !== '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceApprovalList",
                            {
                                "le_id": entityid,
                                "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                                "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                                "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                                "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                                "start_count": 0
                            }
                        ]
                    }
                ]

                complianceApprovallist({
                    payload: payload,
                    paramid: paramid
                })
                setListArray(true)
                setTabvalue(true);
            }
        }
    }, [])

    const columnsremark = [
        {
            title: '#',
            dataIndex: false,
            width: '10px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{(page - 1) * 10 + index + 1}</p>
            }
        },
        {
            title: 'Activity Date',
            dataIndex: "activity_on",
            key: "activity_on",
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{record.activity_on}</p>
            }
        },
        {
            title: 'Usertype',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return record.action === "Submitted" ? <p>Assignee</p> : <p>Approver</p>
            }
        },
        {
            title: 'Reviewer Report',
            dataIndex: false,
            width: '30px',
            align: 'center',
            // render: (text, record, index) => {
            //     return <p>Assignee</p>
            // }
        },
        {
            title: 'Remarks',
            dataIndex: "remarks",
            key: "remarks",
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{record.remarks}</p>
            }
        }
    ]
    const column = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a href={() => false} onClick={() => {

                setDefaultRemarks(record.r_description)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },

    ]

    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: false,
            key: false,
            render: (row, record, index) => {
                // if (record.compliance_task_frequency == 'Periodical') {
                //     return <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#4FC742" }} class="bi bi-square-fill"></i></p></Tooltip>
                // } else if (record.compliance_task_frequency == 'FlexiReview') {
                //     return <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#F4BDD5" }} class="bi bi-square-fill"></i></p></Tooltip>
                // } else if (record.compliance_task_frequency == 'Review') {
                //     return <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#F78453" }} class="bi bi-square-fill"></i></p></Tooltip>
                // }
                // else if (record.compliance_task_frequency == 'On Occurrence') {
                //     return <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#FAF329" }} class="bi bi-square-fill"></i></p></Tooltip>
                // } else {
                //     return <Tooltip title={row && row.compliance_task_frequency}><p><i style={{ color: "#F21625" }} class="bi bi-square-fill"></i></p></Tooltip>
                // }
                return (
                    <>
                        <p>{(page - 1) * 10 + index + 1}</p>
                        {record.compliance_task_frequency == 'Periodical' ?
                            <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#4FC742" }} class="bi bi-square-fill"></i></p></Tooltip> :
                            record.compliance_task_frequency == 'FlexiReview' ?
                                <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#F4BDD5" }} class="bi bi-square-fill"></i></p></Tooltip> :
                                record.compliance_task_frequency == 'Review' ?
                                    <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#F78453" }} class="bi bi-square-fill"></i></p></Tooltip> :
                                    record.compliance_task_frequency == 'On Occurrence' ?
                                        <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#FAF329" }} class="bi bi-square-fill"></i></p></Tooltip> :
                                        <Tooltip title={row && row.compliance_task_frequency}><p><i style={{ color: "#F21625" }} class="bi bi-square-fill"></i></p></Tooltip>
                        }
                    </>
                )
            },
            width: '40px',
            align: 'center',
            filters: [
                {
                    text: <span><i style={{ color: "#4FC742" }} class="bi bi-square-fill"></i> Periodical</span>,
                    value: 'Periodical',
                },
                {
                    text: <span><i style={{ color: "#F4BDD5" }} class="bi bi-square-fill"></i> Flexi Review</span>,
                    value: 'FlexiReview',
                },
                {
                    text: <span><i style={{ color: "#F78453" }} class="bi bi-square-fill"></i> Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "#FAF329" }} class="bi bi-square-fill"></i> On Occurrence</span>,
                    value: 'On Occurrence',
                },
                {
                    text: <span><i style={{ color: "#F21625" }} class="bi bi-square-fill"></i> One Time</span>,
                    value: 'One Time',
                },
            ],
            onFilter: (value, record) => record.compliance_task_frequency == value,
        },
        {
            title: 'Compliance Task',
            ...getColumnSearchProps('compliance_name','Compliance Task'),
            dataIndex: false,
            key: false,
            ellipse: true,
            render: (row, record, index) => {
                return (
                    <>
                        <p className="mt-3 pointer"
                            onClick={() => {
                                console.log(record, "777777");
                                setIndex(index)
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>{record.statu}
                            {/* <div className="mb-3">
                                <Tooltip title={record.description}>
                                    <p style={{ whiteSpace: 'unset' }}><InfoCircleOutlined />&nbsp;{record.compliance_name}</p>
                                </Tooltip>
                            </div> */}
                        </p>
                        <div className="mb-3">
                            <Tooltip title={`${record.description}`} ><ExclamationCircleTwoTone style={{ marginRight: '5px' }} /></Tooltip><span>{record.compliance_name}</span>
                        </div>
                    </>
                )
            },
            width: '240px',
        },
        {
            title: "Domain",
            ...getColumnSearchProps('domain_name',"Domain"),
            dataIndex: 'false',
            key: 'false',
            width: '180px',
            // ellipsis: true,
            render: (row, record) => {
                return (
                    <>
                        <p title={record.domain_name} className="mt-3 pointer cut-text-remarks"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>{record.domain_name}
                            {/* <div className="mb-3">
                                <Tooltip title={record.description}>
                                    <p style={{ whiteSpace: 'unset' }}><InfoCircleOutlined />&nbsp;{record.compliance_name}</p>
                                </Tooltip>
                            </div> */}
                        </p>
                    </>
                )
            },


        },
        {
            title: "Assignee Name",
            ...getColumnSearchProps('assignee_name',"Assignee Name"),
            dataIndex: 'false',
            key: 'false',
            render: (row, record) => {
                console.log(record, 'record')
                return (
                    <>
                        <span className="mt-3 pointer"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>{record.assignee_name}
                        </span>
                    </>
                )
            },
            width: '100px',


        },
        {
            title: "Start Date",
            ...getColumnSearchProps('start_date',"Start Date"),
            dataIndex: 'false',
            key: 'start_date',
            render: (row, record) => {
                return (
                    <>
                        <p className="mt-3 pointer"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>{record.start_date}
                        </p>
                    </>
                )
            },
            width: '100px',


        },
        {
            title: "Due Date",
            ...getColumnSearchProps('due_date',"Due Date"),
            dataIndex: 'false',
            key: 'due_date',
            render: (row, record) => {
                return (
                    <>
                        <p className="mt-3 pointer"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>{record.due_date}
                        </p>
                    </>
                )
            },
            width: '100px',


        },
        {
            title: "Days & Timeline",
            ...getColumnSearchProps('delayed_by',"Days & Timeline"),
            render: (row, record) => {
                return (
                    <>
                        <p className="mt-3 pointer"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>            <span style={{ color: '#FF0000' }}>{record.delayed_by}</span>

                        </p>
                    </>
                )
            },

            sortable: true,
            width: '150px',
            ellipsis: true,
            dataIndex: false,
            key: false,


        },
    ];

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    useEffect(() => {
        validator.current.showMessages();
        validator1.current.showMessages();
        formvalidator.current.showMessages();
    }, [])


    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (list && list.length > 0) {
            let uniqueObjArray = [...new Map(list && list.length && list.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(list, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([...filteredArray, tempArr])
            }
        }
    }, [list])


    useEffect(() => {
        if (isAuth) {
            if (entityid !== '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceApprovalList",
                            {
                                "le_id": entityid,
                                "unit_id": null,
                                "d_id": null,
                                "frequency_id": null,
                                "us_id": null,
                                "start_count": 0
                            }
                        ]
                    }
                ]
                const filterAPI = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "complianceApprovalFilters",
                            {
                                "le_id": entityid
                            }
                        ]
                    }
                ]
                if (entityid != "null") {
                    getComplianceFilters({
                        payload: filterAPI,
                        paramid: paramid
                    })
                }
            }

        }
    }, [isAuth, entityid])

    const showValue = () => {
        setShowValueModal(true);
    };

    const handleOk = () => {
        setRemarksModal(false);

    };

    const download = (item) => {
        console.log(item, 'itemitemitem')
        const downloadpayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFile_temp",
                        {
                            "le_id": entityid,
                            "c_id": 1,
                            "d_id": valueForFileUpload.d_id,
                            "u_id": valueForFileUpload.u_id,
                            "start_date": valueForFileUpload.start_date,
                            "compliance_history_id": valueModal.compliance_history_id,
                            "upload_type": 2,
                            "file_name": item,
                        }
                    ]
                }
            ]
        Downloadfile({
            payload: downloadpayload,
            paramid: paramid
        });
        // if (item) {
        //     window.open('https:/' + item);
        // }
    }
    const Remove = (index) => {

        let temp = fileupload
        for (let i in fileupload) {
            const removepayload =
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "RemoveFile_temp",
                            {
                                "le_id": entityid,
                                "c_id": 1,
                                "selected_task_info": [
                                    {
                                        "d_id": valueForFileUpload.d_id,
                                        "u_id": valueForFileUpload.u_id,
                                        "start_date": valueForFileUpload.start_date,
                                        "compliance_history_id": valueModal.compliance_history_id,
                                        "file_info": [
                                            {
                                                "file_size": fileupload.file_size,
                                                "file_name": fileupload.file_name,
                                                "file_content": null
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            removedocument({
                payload: removepayload,
                paramid: paramid
            })


            console.log(temp[i]['file_name'], 'indexxx')
            if (fileupload[i]['file_name'] == index) {
                fileupload.splice(i, 1)
            }

        }
        setFileUpload([...fileupload])

    }


    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    const handleModalValue = () => {
        let payload;
        let documents = [];
        setAddFormSubmit1(true)
        if (validator1.current.allValid()) {
            if (valueModal.action == 'Approve') {
                setShowValueModal(false)
                setEdit(false)
                payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "ApproveCompliance",
                            {
                                "le_id": entityid,
                                "compliance_history_id": valueModal.compliance_history_id,
                                "approval_status": [
                                    "Approve"
                                ],
                                "remarks": defaultRemarks ? defaultRemarks : null,
                                "next_due_date": valueModal.next_due_date ? moment(new Date(valueModal.next_due_date)).format('DD-MMM-YYYY') : null,
                                "validity_date": valueModal.validity_date ? moment(new Date(valueModal.validity_date)).format('DD-MMM-YYYY') : null,
                                "password": "",
                                "pwd_validate": false,
                                "documents": null,
                                "file_size": 0
                            }
                        ]
                    }
                ]
                const payload1 = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceApprovalList",
                            {
                                "le_id": entityid,
                                "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                                "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                                "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                                "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                                "start_count": 0
                            }
                        ]
                    }
                ]

                approveCompliance({
                    payload: payload,
                    paramid: paramid,
                    tempvalue: 'Approve',
                    nextData: list[index + 1],
                    payload1: payload1

                })
                setAddFormSubmit1(false);
                setValueModal({
                    unit: '',
                    compliance_history_id: '',
                    compliance_task: '',
                    compliance_frequency: '',
                    uploaded_document: '',
                    document_issued_date: '',
                    document_reference_number: '',
                    validity_date: '',
                    next_due_date: '',
                    status: '',
                    remarks: '',
                    action: '',
                    password: '',
                    history_count: '',
                    occurrence_remarks: ''
                })
                // setShowValueModal(false);
            } else if (valueModal.action == 'Reject Approval') {
                Swal.fire({
                    title: 'Are You Sure?',
                    icon: 'info',
                    html: 'If you Reject,task will move to Not Complied Status,<br/>do you still want to proceed?',
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: 'Ok',
                    cancelButtonText:
                        'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        Setpassvisible(true);
                    }
                })

            } else {
                if (fileupload && fileupload.length > 0) {
                    for (let i in fileupload) {
                        documents.push({
                            "file_size": fileupload && fileupload.length && fileupload[i] && fileupload[i].file_size,
                            "file_name": fileupload && fileupload.length && fileupload[i] && fileupload[i].file_name,
                            "file_content": null
                        })
                    }
                }
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            'ApproveCompliance', {
                                'le_id': entityid,
                                'compliance_history_id': valueModal.compliance_history_id,
                                'approval_status': [valueModal.action],
                                "remarks": defaultRemarks ? defaultRemarks : null,
                                "next_due_date": valueModal.next_due_date ? moment(new Date(valueModal.next_due_date)).format('DD-MMM-YYYY') : null,
                                "validity_date": valueModal.validity_date ? moment(new Date(valueModal.validity_date)).format('DD-MMM-YYYY') : null,
                                'password': "",
                                'pwd_validate': false,
                                'documents': documents,
                                'file_size': 0
                            }
                        ]
                    }
                ]
                const payload1 = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceApprovalList",
                            {
                                "le_id": entityid,
                                "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                                "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                                "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                                "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                                "start_count": 0
                            }
                        ]
                    }
                ]
                approveCompliance({
                    payload: payload,
                    paramid: paramid,
                    tempvalue: 'Rectify',
                    nextData: list[index + 1],
                    payload1: payload1
                })

                setAddFormSubmit1(false);
                setValueModal({
                    unit: '',
                    compliance_history_id: '',
                    compliance_task: '',
                    compliance_frequency: '',
                    uploaded_document: '',
                    document_issued_date: '',
                    document_reference_number: '',
                    validity_date: '',
                    next_due_date: '',
                    status: '',
                    remarks: '',
                    action: '',
                    password: '',
                    history_count: '',
                    occurrence_remarks: '',
                })
                // setShowValueModal(false);


            }

        }
    }


    const compliancesubmit = () => {
        setAddFormSubmit2(true)
        if (formvalidator.current.allValid()) {
            Setpassvisible(false);
            setShowValueModal(false)
            setEdit(false)

            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        'ApproveCompliance', {
                            'le_id': entityid,
                            'compliance_history_id': valueModal.compliance_history_id,
                            'approval_status': [valueModal.action],
                            "remarks": defaultRemarks ? defaultRemarks : null,
                            "next_due_date": valueModal.next_due_date ? moment(new Date(valueModal.next_due_date)).format('DD-MMM-YYYY') : null,
                            "validity_date": valueModal.validity_date ? moment(new Date(valueModal.validity_date)).format('DD-MMM-YYYY') : null,
                            'password': password.passvalue,
                            'pwd_validate': true,
                            'documents': null,
                            'file_size': 0
                        }
                    ]
                }
            ]
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApprovalList",
                        {
                            "le_id": entityid,
                            "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                            "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                            "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                            "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                            "start_count": 0
                        }
                    ]
                }
            ]
            approveCompliance({
                payload: payload,
                paramid: paramid,
                tempvalue: 'Reject',
                nextData: list[index + 1],
                payload1: payload1
            })
            setAddFormSubmit2(false)
            setAddFormSubmit1(false)
            setValueModal({
                unit: '',
                compliance_history_id: '',
                compliance_task: '',
                compliance_frequency: '',
                uploaded_document: '',
                document_issued_date: '',
                document_reference_number: '',
                validity_date: '',
                next_due_date: '',
                status: '',
                remarks: '',
                action: '',
                password: '',
                history_count: '',
                occurrence_remarks: '',
            })
        }
        //}
    }



    const handleCancel = () => {
        setRemarksModal(false)
        setEdit(false)
    };

    const Cancelmodal = () => {
        setAddFormSubmit1(false)
        setShowValueModal(false);
        setValueModal({
            ...valueModal,
            action: '',
            remarks: '',
            validity_date: '',
            next_due_date: ''
        })
        setEdit(false)

    }

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content,
        };

        return result;
    }

    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    }

    const SavepastRecordOnChange = (e) => {
        if (e.target.name === 'file') {
            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
            let files = e.target.files
            let file_max_size = 26214400
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var file_name = file.name;
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                if (fileRegex.test(f_name) === false) {
                    return;
                }
                let file_size = file.size;
                if (file_size > file_max_size) {
                    toast.error("Maximum filesize limit exceed")
                    return;
                }
                if (file) {
                    convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                        var fN = name.substring(0, name.lastIndexOf('.'));
                        var fE = name.substring(name.lastIndexOf('.') + 1);
                        var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                        var f_Name = fN + '-' + uniqueId + '.' + fE;

                        var result = uploadFileFormat(size, f_Name, file_content);
                        setFileData(result)
                        results.push(result);


                        let temp1 = fileupload
                        let temp = []
                        if (results && results.length == 1) {
                            for (let i in results) {
                                temp1.push(results[i])
                            }
                            setFileUpload([...temp1])
                        }
                        else {
                            for (let i in results) {
                                temp.push(results[i])
                            }
                            setFileUpload([...temp])
                        }

                        const filePayload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "UploadComplianceTask_temp_upload",
                                    {
                                        "le_id": entityid,
                                        "c_id": 1,
                                        "d_id": valueForFileUpload.d_id,
                                        "u_id": valueForFileUpload.u_id,
                                        "start_date": valueForFileUpload.start_date,
                                        "file_info": results,
                                        "compliance_history_id": valueModal.compliance_history_id,
                                    }
                                ]
                            }
                        ]
                        UploadFile({
                            payload: filePayload,
                            paramid: paramid
                        })
                    });
                }
            }
        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5" style={{ backgroundColor: '' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            )
        })
    }


    const passwordcancel = () => {
        setAddFormSubmit1(false)
        Setpassvisible(false);
        setValueModal({
            ...valueModal,
            password: ''
        })
        setpassword({
            ...password,
            passvalue: ''
        })
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    console.log(list, "listlist");
    console.log(valueModal, "valueModalvalueModal");
    return (
        <div className="page-wrapper">
            <div className="page-titles pb-0 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Compliance Approval</span>
                                </li>
                            </ol>
                            {/* <h2 className="mb-0 fw-bold"><b>Compliance Approval</b></h2> */}
                        </nav>

                    </div>

                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className={ localStorage.getItem('currentTheme') +' col-lg-12 card-body1-statuatory'}>
                            <Collapse className='report-collapse' accordion bordered={true} defaultActiveKey={['1']}>
                                <Panel header="Compliance Approval" style={{ backgroundColor: '#87CEEB' }} key="1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}> *</span><br />
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Legal Entity"
                                                                onChange={(data, value) => {
                                                                    if (data != undefined) {
                                                                        setCurrentEntity(data)
                                                                        setShowFilterData({
                                                                            ...showFilterData,
                                                                            le_id: data,
                                                                            domain_name: '',
                                                                            unit_name: '',
                                                                            user_name: '',
                                                                            frequency_name: ''
                                                                        })
                                                                    }
                                                                    else {
                                                                        setCurrentEntity('')
                                                                        setShowFilterData({
                                                                            ...showFilterData,
                                                                            le_id: '',
                                                                            domain_name: '',
                                                                            unit_name: '',
                                                                            user_name: '',
                                                                            frequency_name: ''
                                                                        })
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={showFilterData.le_id||undefined}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'le_id',
                                                                showFilterData.le_id,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Domain : </b></label><br />
                                                    <div className="form-group">
                                                        <Select
                                                            disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Domain"
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        d_id: data,
                                                                        domain_name: value.children,
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        frequency_name: ''
                                                                    })
                                                                }
                                                                else {
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        d_id: '',
                                                                        domain_name: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        frequency_name: ''
                                                                    })
                                                                }

                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showFilterData.domain_name || undefined}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                        >
                                                            {domainList && domainList.length && domainList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Unit : </b></label><br />
                                                    <div className="form-group">
                                                        <Select
                                                            disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Unit"
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        unit_id: data,
                                                                        unit_name: value.children
                                                                    })
                                                                }
                                                                else {
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        unit_id: '',
                                                                        unit_name: ''
                                                                    })
                                                                }
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showFilterData.unit_name || undefined}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                        >
                                                            {unitList && unitList.length && unitList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.unit_id} value={item.unit_name}>
                                                                        {item.unit_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{marginTop:'10px'}}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Frequency : </b></label><br />

                                                    <div className="form-group">
                                                        <Select allowClear={true}
                                                            size="default"
                                                            placeholder="Select Frequency"
                                                            // className='select2 form-control custom-select bg-white'
                                                            value={showFilterData.frequency_name || undefined}
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        frequency_id: data,
                                                                        frequency_name: value.children
                                                                    })
                                                                }
                                                                else {
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        frequency_id: '',
                                                                        frequency_name: ''
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                        >
                                                            <Option key={0}>All</Option>
                                                            {frequencyList && frequencyList.length && frequencyList.map((item) => {
                                                                return <Option key={item.frequency_id}>{item.frequency_name}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>User : </b></label><br />
                                                    <div className="form-group">
                                                        <Select

                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter User"
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        us_id: data,
                                                                        user_name: value.children
                                                                    })
                                                                }
                                                                else {
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        us_id: '',
                                                                        user_name: ''
                                                                    })
                                                                }
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showFilterData.user_name || undefined}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                        >
                                                            {userList && userList.length && userList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.user_id}>
                                                                        {item.employee_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 pt-2 pb-1" style={{ textAlign: 'center' }}>
                                        {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF"
                                        }} icon={<EyeOutlined />}
                                            size='default'
                                            onClick={() => {
                                                setListArray(true)
                                                setAddFormSubmit(true);
                                                setTabvalue(true);
                                                if (validator.current.allValid()) {
                                                    const payload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetComplianceApprovalList",
                                                                {
                                                                    "le_id": entityid,
                                                                    "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                                                                    "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                                                                    "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                                                                    "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                                                                    "start_count": 0
                                                                }
                                                            ]
                                                        }
                                                    ]

                                                    complianceApprovallist({
                                                        payload: payload,
                                                        paramid: paramid
                                                    })
                                                }
                                            }}
                                        >Show</Button>
                                    </div>
                                </Panel>
                            </Collapse>
                            {tabValue == true ?
                                <div>

                                    {listArray === false ?
                                        <div>
                                            <Collapse  className='pt-2' defaultActiveKey={['1']} accordion>
                                                <Panel header="Compliance Table" style={{ backgroundColor: '#87CEEB' }}key="0">

                                                    {filteredArrayState && Array.isArray(filteredArrayState) && filteredArrayState.length > 0 ?
                                                        <div>
                                                            {filteredArrayState.map((item, i) => {

                                                                return (

                                                                    <Table
                                                                        columns={columns}
                                                                        className={localStorage.getItem("currentTheme")}
                                                                        size={'small'}
                                                                        // className='userprivclass'
                                                                        dataSource={item}
                                                                        bordered
                                                                        title={() => {
                                                                            return item ? item && item.length && item[0].unit_name : 'Table'
                                                                        }}
                                                                        scroll={{ y: 400 }}
                                                                        pagination={{
                                                                            defaultPageSize: dataTableProperties.pagesize,
                                                                            showSizeChanger: dataTableProperties.sizechanger
                                                                        }}
                                                                    />
                                                                )
                                                            })}</div> :
                                                        <div>
                                                            <span>No Compliance Available</span>
                                                        </div>}

                                                </Panel>
                                            </Collapse>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-6' >
                                                        <label style={{ marginLeft: "28px" }}>Total Compliance : {filteredArrayState.length}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div>
                                            <Collapse className='pt-2' defaultActiveKey={['0']} accordion>
                                                <Panel header="Compliance Table" style={{ backgroundColor: '#87CEEB' }} key="0">
                                                    {list && list.length > 0 ?
                                                        <Table
                                                            size={'small'}
                                                            // className='userprivclass'
                                                            className={localStorage.getItem("currentTheme")}
                                                            columns={columns}
                                                            dataSource={list}
                                                            bordered
                                                            title={() => {
                                                                return list && list.length > 0 ? list[0].unit_name : null
                                                            }}
                                                            scroll={{ y: 1000 }}
                                                            pagination={false}
                                                        /> :
                                                        <div>
                                                            <span style={{ marginLeft: '40%', fontWeight: 'bold' }}>No Compliance Available</span>
                                                        </div>}
                                                </Panel>
                                            </Collapse>
                                            <div className='col-md-12 mt-1'>
                                                <div className='row'>
                                                    <div className='col-md-6' >
                                                        <label>Total Compliance : {list.length}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div> : null}

                            <Modal title="" visible={showValueModal} footer={null} cancelText={false} onCancel={Cancelmodal} width={50}>
                                {/* <div className="row"> */}
                                <div className="col-md-12" >
                                    <div className="row" >
                                        <div className="col-md-6" >
                                            <label htmlFor=""><h5>Unit Name:</h5></label>
                                        </div>
                                        <div className="col-md-6">
                                            <p style={{ float: 'left' }}><Tooltip title={list && list.length > 0 && list[0].unit_address} >
                                                <ExclamationCircleTwoTone />
                                            </Tooltip> &nbsp;{valueModal.unit}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: "5px" }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor=""><h5>Compliance Task:</h5></label>
                                        </div>
                                        <div className="col-md-6">
                                            <p style={{ float: 'left' }}><Tooltip placement='bottom' title={valueModal.description} >
                                                <ExclamationCircleTwoTone />
                                            </Tooltip> &nbsp;{valueModal.compliance_task}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: "5px" }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor=""><h5>Compliance Frequency:</h5></label>
                                        </div>
                                        <div className="col-md-6">
                                            <p style={{ float: 'left' }}>{valueModal.compliance_frequency}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: "5px" }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor=""><h5>Uploaded Documents:</h5></label>
                                        </div>
                                        <div className="col-md-6">
                                            {valueModal.uploaded_document != null ?
                                                valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                                                    return (
                                                        <> < p style={{ float: 'left' }}> {item} <span> <DownloadOutlined
                                                            onClick={(e) => {
                                                                const remarkapi = [
                                                                    authtoken,
                                                                    {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "DownloadFile",
                                                                            {
                                                                                "le_id": entityid,
                                                                                "c_id": countryidd,
                                                                                "d_id": valueModal.domain_id,
                                                                                "u_id": Number(valueModal.unit_id),
                                                                                "start_date": valueModal.start_date,
                                                                                "file_name": valueModal.uploaded_document[i]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                                if (entityid != "null") {
                                                                    getdownloadpath({
                                                                        payload: remarkapi,
                                                                        paramid: paramid,
                                                                    })
                                                                }
                                                            }}
                                                            style={{ color: "blue" }} /></span> </p>
                                                            {/* <span> <DownloadOutlined /></span> */}
                                                        </>)
                                                }) : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: "10px" }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor=""><h5>Task Completion Date/Document Issued Date:</h5></label>
                                        </div>
                                        <div className="col-md-6">
                                            <p style={{ float: 'left' }}>{valueModal.document_issued_date}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: "10px" }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor=""><h5>Document Reference Number:</h5></label>
                                        </div>
                                        <div className="col-md-6">
                                            <p style={{ float: 'left' }}>{valueModal.document_reference_number}</p>
                                        </div>
                                    </div>
                                </div>
                                {valueModal.compliance_frequency == 'Periodical' || valueModal.compliance_frequency == 'FlexiReview' || valueModal.compliance_frequency == 'Review' ?
                                    <>
                                        <div className="col-md-12" style={{ marginTop: "10px" }}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><h5>Validity Date:</h5></label>
                                                </div>
                                                <div className="col-md-6">
                                                    {edit === false ?
                                                        <a href={() => false}>
                                                            <span
                                                                className="btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                                            >
                                                                <i className="ri-edit-line" onClick={() => {
                                                                    setEdit(true)
                                                                }}></i>
                                                            </span>
                                                        </a> :
                                                        <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder={'DD-MMM-YYYY'} className='form-control' value={valueModal.validity_date ? moment(valueModal.validity_date) : ''} name="validity_date" id="" onChange={(dateString) => {
                                                            setValueModal({
                                                                ...valueModal,
                                                                validity_date: dateString
                                                            })
                                                        }} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: "10px" }}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><h5>Next Due Date: <span style={{ "color": "red" }}>*</span></h5></label>
                                                </div>
                                                <div className="col-md-6">
                                                    {edit === true ?
                                                        <DatePicker allowClear={false} format={'DD-MMM-YYYY'} className='form-control' disabled={moment(valueModal.next_due_date) < moment(currentDate)} value={valueModal.next_due_date ? moment(valueModal.next_due_date) : ''} name="next_due_date" id="" onChange={(dateString) => {
                                                            setValueModal({
                                                                ...valueModal,
                                                                next_due_date: dateString
                                                            })
                                                        }} />
                                                        :
                                                        <> <p style={{ float: 'left' }}>{valueModal.next_due_date}</p> </>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                                {valueModal.compliance_frequency == 'On Occurrence' ?
                                    <>
                                        <div className="col-md-12" style={{ marginTop: "5px" }} >
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><h5>Onoccurance Remarks:</h5></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <p style={{ float: 'left' }}>{valueModal.occurrence_remarks}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : ''}
                                <div className="col-md-12" style={{ marginTop: "5px" }} >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor=""><h5>Status:</h5></label>
                                        </div>
                                        <div className="col-md-6">
                                            <p style={{ float: 'left' }}>Submitted</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: "5px" }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor=""><h5>Remarks: </h5></label>
                                        </div>
                                        <div className="col-md-6">
                                            {/* <p style={{ float: 'left' }} placeholder="Enter Remarks"
                                             onChange={(e) => {
                                                setValueModal({
                                                    ...valueModal,
                                                    remarks: e.target.value
                                                })
                                            }}></p> */}

                                            <span>{valueModal.remarks}</span>
                                            {valueModal.history_count != 0 ?
                                                <>
                                                    <Tooltip title="click to view remark history" ><ExclamationCircleTwoTone style={{ marginLeft: '5px' }} onClick={(e) => {
                                                        showModalremark()
                                                        setToolTip([valueModal])
                                                        const remarkapi = [
                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request": [
                                                                    "GetComplianceRemarksHistoryData",
                                                                    {
                                                                        "legal_entity_id": entityid,
                                                                        "unit_id": Number(valueModal.unit_id),
                                                                        "compliance_history_id": Number(valueModal.compliance_history_id)
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                        getremarkhistory({
                                                            payload: remarkapi,
                                                            paramid: paramid
                                                        })
                                                    }
                                                    } /></Tooltip></> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: "10px" }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor=""><h5>Action:<span style={{ "color": "red" }}>*</span></h5>
                                            </label>
                                        </div>
                                        <div className="col-md-5 ">
                                            <div className="form-group">
                                                <Select name="action" className='form-control' value={valueModal.action} placeholder="Select" onChange={(e) => {
                                                    setValueModal({
                                                        ...valueModal,
                                                        action: e
                                                    })
                                                    setDefaultRemarks('')
                                                }} id="">
                                                    {/* <Option defaultValue value="">Select</Option> */}
                                                    <Option value="Approve">Approve</Option>
                                                    <Option value="Rectify Approval">Rectify</Option>
                                                    <Option value="Reject Approval">Reject</Option>
                                                </Select>
                                                {validator1.current.message(
                                                    'action',
                                                    valueModal.action,
                                                    ['required'],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: ' Action Required'
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    {valueModal.action === 'Rectify Approval' ?
                                        <div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6 mt-3">
                                                        <label htmlFor=""><h5>Remarks: <span style={{ "color": "red" }}>*</span></h5></label>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="col-md-10"  style={{paddingRight: '0px'}}>
                                                                <TextArea name="remarks" className='form-control' placeholder="Enter Remarks" id="" cols="10" rows="0" value={defaultRemarks} onChange={(e) => {
                                                                    setDefaultRemarks(e.target.value)
                                                                }}></TextArea></div>
                                                                <div className='col-md-2 p-0'>
                                                                <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center" style={{ float: 'left' }}
                                                                    onClick={() => { showRemarksModal() }}>
                                                                    <PlusCircleOutlined />
                                                                </span>
                                                                {validator1.current.message(
                                                                    'remarks',
                                                                    defaultRemarks,
                                                                    ['required'],
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Remarks Required'
                                                                        }
                                                                    })}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6 mt-3">
                                                        <label htmlFor=""><h5>Upload Documents: </h5></label>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <input type="file" multiple name="file" onChange={(e) => {
                                                            SavepastRecordOnChange(e)
                                                        }} id="" />
                                                        <p>(Maximum 25MB per file/task)</p>
                                                        <p> Upload Date  :{moment(currentdate).format("DD-MMM-YYYY")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6 mt-3">
                                                        <label htmlFor=""><h5>Uploaded File Size: </h5></label>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                                            return <p>{item.file_size} KB</p>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6 mt-3">
                                                        <label htmlFor=""><h5>Attached Documents: </h5></label>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                                            return <p>{item.file_name}
                                                                <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                    icon={<DownloadOutlined />} size='small' onClick={(e) => { download(item.file_name) }}>
                                                                </Button> &nbsp;
                                                                <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                    icon={< CloseOutlined />} size='small' onClick={(e) => { Remove(item.file_name) }}>
                                                                </Button></p>
                                                        })}



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : valueModal.action === 'Reject Approval' ?
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6 mt-3">
                                                        <label htmlFor=""><h5>Remarks : </h5></label>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="col-md-10" style={{paddingRight: '0px'}}>
                                                                <TextArea name="" className='form-control' id="remarks" cols="10" rows="0" value={defaultRemarks} onChange={(e) => {
                                                                    setDefaultRemarks(e.target.value)
                                                                }}></TextArea>
                                                                </div><div className="col-md-2 p-0">
                                                                <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center" style={{ float: 'left',marginTop: '18px' }}
                                                                    onClick={() => { showRemarksModal() }}>
                                                                    <PlusCircleOutlined />
                                                                </span>
                                                                {validator1.current.message(
                                                                    'remarks',
                                                                    defaultRemarks,
                                                                    ['required'],
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Remarks Required'
                                                                        }
                                                                    })}
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            : ''

                                    }
                                </div>
                                <br />
                                <div className="form-actions text-center popupbtncolour">
                                    <Button type="primary" shape="round" className={'addbutton '+ localStorage.getItem('currentTheme')}
                                        style={{ background: "#198754", borderColor: "#198754" , textAlign:'center' }}
                                        icon={<PlayCircleOutlined />} size='default'
                                        onClick={handleModalValue}
                                    >
                                        Submit
                                    </Button>
                                        {/* <div class="button2" id={localStorage.getItem('currentTheme')} onClick={handleModalValue}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Submit
                                        </div> */}
                                </div>
                            </Modal>
                            <Modal title="Remarks List" footer={null} visible={remarksModal} onOk={handleOk}
                                onCancel={handleCancel} className="remarksClass add-service-prv">
                                <Table
                                    // className='userprivclass'
                                    size={'small'}
                                    columns={column}
                                    dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                                    bordered
                                    pagination={{
                                        defaultPageSize: dataTableProperties.pagesize,
                                        showSizeChanger: dataTableProperties.sizechanger,
                                        pageSizeOptions: dataTableProperties.pageSizeOptions
                                    }}
                                />
                            </Modal>
                            <Modal visible={passvisible} className="newStyleModalPassword" footer={null} onCancel={passwordcancel}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-6">
                                            <label className="control-label"> <b>Password: </b><span style={{ "color": "red" }}>*</span></label>
                                            <input
                                                type="password"
                                                id="password"
                                                name='passwordvalue'
                                                autoComplete='off'
                                                placeholder="Enter password"
                                                onChange={(e) => {
                                                    setpassword({
                                                        ...password,
                                                        passvalue: e.target.value
                                                    })
                                                }}
                                                value={password.passvalue}
                                                style={{ "margin-top": "5px", width: '100%' }}
                                                className="form-control"
                                            />
                                            {formvalidator.current.message(
                                                'Password',
                                                password.passvalue,
                                                ['required'],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Password Required'
                                                    }
                                                })}
                                        </div>
                                    </div>

                                </div>
                                <br />
                                <div className="form-actions">
                                    <Button type="primary" shape="round" className='addbutton'
                                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                                        icon={<PlayCircleOutlined />} size='default' onClick={compliancesubmit}>Submit
                                    </Button>
                                </div>
                            </Modal>
                            {valueModal.remarks != "" || null ?
                                <Modal title="Remarks History" className='add-service-prv' visible={isModalOpenremark} footer={null} onCancel={handleCancelremark}>

                                    <Table
                                        size={'small'}
                                        columns={columnsremark}
                                        dataSource={remarklist.remarks_history}
                                        bordered
                                        pagination={false}
                                    />
                                </Modal> : ""}
                        </div>
                </div>
            </div >
        </div >

    )
}


export default connect(mapStateToProps, {
    complianceApprovallist,
    getComplianceFilters,
    approveCompliance,
    GetRemarksData,
    UploadFile,
    Downloadfile,
    removedocument,
    getremarkhistory,
    getdownloadpath
})(ComplianceApproval)
