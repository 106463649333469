import { Card, Tabs, Collapse, Select, Tooltip, Button, Table, Input } from 'antd';
import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import moment from 'moment';
import EncryptDecrypt from './../../../src/Libs/Encrypt&Decrypt'
import { Toaster } from '../../../src/Libs/Toaster'
import { InfoCircleOutlined, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, DownOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';
import { getCompletedTask, getStatutoriesByUnit, SaveCompletedTask, UploadFile, Movetaskfile } from './../../Store/Action/Transcations/CompletedtaskYear'
// import { file } from '@babel/types';
const { Option } = Select;
const _ = require("lodash");
const mapStateToProps = (state) => ({
    list: state.Completedtaskyear.completedTask,
    statutoryUnitList: state.Completedtaskyear.statutoryByUnit,
    totalCount: state.Completedtaskyear.statutoryByUnit.totalCount,
    batchCount: state.Completedtaskyear.statutoryByUnit.batchCount,
    IsClosed: state.Completedtaskyear.statutoryByUnit.IsClosed
})
const CompletedTaskCurrentYear = ({
    getCompletedTask, list, getStatutoriesByUnit, statutoryUnitList, batchCount, totalCount, SaveCompletedTask, IsClosed,
    UploadFile, Movetaskfile
}) => {
    const [count, setCount] = useState({
        batch_count: 0,
        start_count: 0
    })
    const { TabPane } = Tabs;
    const { Panel } = Collapse;
    const [cid, setCID] = useState(null)
    const [unit, setUnit] = useState({
        legal_entity: '',
        division: '',
        category: '',
        unit: ''
    })
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '20', '30']
    })
    const [rowcomplianceid, setRowComplianceId] = useState(null)
    console.log(rowcomplianceid, "rowcomplianceid");
    const [show, setShow] = useState(false)
    const [fileData, setFileData] = useState({})
    const [option, setOption] = useState([])
    const [tempList, setTempList] = useState([])
    const [collapse, setCollapse] = useState([])
    const [collapseValue, setCollapseValue] = useState([])
    const [domains, setDomain] = useState({
        domain: '',
        acts: '',
        frequency: ''
    })
    const [SavepastRecords, setSavePastRecords] = useState([])
    console.log(SavepastRecords, "SavepastRecords");
    const [checkbox, setCheckBox] = useState(false)
    const [checkboxData, setCheckBoxData] = useState([])
    console.log(checkboxData, 'checkboxData');
    const [Act, setAct] = useState([])
    const [entity, setEntity] = useState([{
        le_id: localStorage.getItem('SelectedEntityid'),
        le_name: localStorage.getItem('SelectedEntity')

    }])
    const [category, setCategory] = useState([
    ])
    const [dataList, setDataList] = useState([
    ])
    const [entires, setEntires] = useState(null)
    const [status, setStatus] = useState([])
    console.log(status, "statusstatus");
    const [units, setUnits] = useState([
    ])
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    //   const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [selectUnit, setSelectUnit] = useState(false);
    const [fileupload, setFileUpload] = useState([])
    const [selectDomain, setSelectDomain] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const Domainvalidator = useRef(new SimpleReactValidator());
    const [tab, setTab] = useState("1")
    const [date, setDate] = useState([])

    const [entityid, setCurrentEntity] = useState('');
    console.log(entityid, "entityid");
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    const onChange = (key) => {
        setTab(key)
    };
    const showMore = () => {
        setShow(true)
        setDataList([])
        const payloads = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetStatutoriesByUnit",
                    {
                        'le_id': localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                        'unit_id': Number(unit.unit),
                        'domain_id': Number(domains.domain),
                        'level_1_statutory_name': domains.acts,
                        'compliance_task_frequency': domains.frequency,
                        'start_count': count.start_count,
                        'batch_count': count.batch_count + 1

                    }

                ]

            }
        ]
        getStatutoriesByUnit({
            payload: payloads,
            paramid: paramid
        })

    }
    // const d = new Date();
    // const today = d.toLocaleDateString();
    const columns = [
        {
            title: 'Compliance Task',
            dataIndex: 'description',
            key: 'description',
            // ellipsis: true,
            width: '150px',
            render: (text, record) => {
                <span style={{ marginLeft: '3px' }}> <ExclamationCircleTwoTone /></span>
                return <><Tooltip title={text} >
                    <span style={{ marginLeft: '3px' }}> <ExclamationCircleTwoTone /></span>
                </Tooltip>
                    <span style={{ marginLeft: '5px' }}>{record.compliance_name}</span></>
            }
        },
        {
            title: 'Compliance Frequency',
            dataIndex: 'compliance_task_frequency',
            key: 'compliance_task_frequency',
            width: '100px',
            align: 'center'
        },
        {
            title: 'Statutory Date',
            dataIndex: 'pr_statutory_date',
            key: 'pr_statutory_date',
            width: '100px',
        },
        {
            title: <label>Due Date <span style={{ color: "red" }}>*</span></label>,
            dataIndex: 'due_date',
            key: 'due_date',
            width: '100px',
        },
        {
            title: <label>Task Completion Date / Document Issued Date <span style={{ color: "red" }}>*</span></label>,
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            width: '150px',
            render: (text, record, index) => {
                return (

                    <Input
                        type="date"
                        className="form-control"
                        // name='file'
                        max={new Date().toISOString().split('T')[0]}
                        onBlur={(e) => {
                            let temparr = []
                            let temp = []
                            let temp2 = []
                            collapseValue.map((item, i) => {
                                if (item === text) {
                                    collapseValue.length > 0 && collapseValue.splice(i, 1);
                                    setCollapseValue([...collapseValue, ...temp])
                                }

                            })
                            let rowDate = moment(record.due_date).format('DD-MMM-YYYY')
                            let givenDate = moment(e.target.value).format('DD-MMM-YYYY')
                            if (moment(rowDate).isBefore(givenDate)) {
                                temp.push(text)
                            }

                            temp2.push({
                                uniqueId: text,
                                date: e.target.value,
                                index: index,
                                collapse: record.primary_legislation
                            }


                            )
                            setCollapse([...collapse, ...temp2])
                            setCollapseValue([...collapseValue, ...temp])

                            date.map((item, i) => {
                                if (item.uniqueId === text) {
                                    date && date.length && date.splice(i, 1);
                                    setDate([...date, ...temparr])
                                }

                            })
                            temparr.push({
                                date: e.target.value,
                                index: index,
                                uniqueId: text,
                                collapse: record.primary_legislation
                            })
                            setDate([...date, ...temparr])
                            let temparr2 = []
                            temparr2.push(text)
                            setOption([...option, ...temparr2])

                        }

                        }
                    />
                )
            }
        },
        {
            title: 'Document Upload',
            dataIndex: 'description',
            key: 'description',
            width: '150px',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <input
                        type="file"
                        name="file"
                        onChange={(e) => {
                            SavepastRecordOnChange(record, e)
                        }
                        }
                    />
                )
            }
        },
        {
            title: <label>Assignee <span style={{ color: "red" }}>*</span></label>,
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            // ellipsis: true,
            width: '100px',
        },
        {
            title: <label>Compliance Status <span style={{ color: "red" }}>*</span></label>,
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            width: '125px',
            render: (text, record) => {
                if (collapseValue.includes(text)) {
                    return (
                        <>
                            <Select name="option"
                                className="form-control" value={"2"}>
                                <Option value="1">Complied </Option>
                                <Option value="2" >Delayed Complied</Option>
                            </Select>
                        </>
                    )

                }
                return (
                    <>
                        <Select name="option"
                            className="form-control" value={"1"}>
                            <Option value="1">Complied </Option>
                            <Option value="2" >Delayed Complied</Option>
                        </Select>

                    </>

                )
            }
        },
        {
            title: <label>Completed<span style={{ color: "red" }}>*</span></label>,
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            // ellipsis: true,
            width: '90px',
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => {

                return (
                    <input
                        type="checkbox"
                        onChange={(e) => {
                            console.log(e, "eeeeeeeeeee");
                            let tempArray = []
                            let temp = status;
                            const index1 = temp.indexOf(index);

                            if (index1 > -1) {
                                temp.splice(index1, 1);
                                setStatus([...temp])
                            } else {
                                temp.push(index)
                                setStatus([...temp])

                            }

                            // if(temp.includes(e)){
                            // temp.splice()
                            // }
                            // temp.push(index)
                            // setStatus([...temp])
                            if (e.target.checked) {
                                console.log(index, "indexindex");
                                for (let i in date) {
                                    if (date[i].uniqueId == text) {
                                        console.log(date[i].uniqueId, "hhhhhhh");
                                        console.log(record, "texttext");
                                        tempArray.push({
                                            unit_id: Number(unit.unit),
                                            compliance_id: record.compliance_id,
                                            due_date: record.due_date,
                                            completion_date: moment(date[i].date).format('DD-MMM-YYYY'),
                                            documents: fileupload.length ? [{
                                                file_size: fileupload[0].file_size,
                                                file_name: fileupload[0].file_name,
                                                file_content: null
                                            }] : [],
                                            validity_date: null,
                                            pr_completed_by: 2,
                                            amendment_history_id: record.amendment_history_id,
                                            indexValue: index
                                        })
                                    }

                                }

                                setSavePastRecords([...SavepastRecords, ...tempArray])
                                setCheckBox(true)
                                let temparr = []
                                temparr.push(record.uniqueId)
                                setCheckBoxData([...checkboxData, ...temparr])
                            }

                            else {
                                for (let i in SavepastRecords) {
                                    if (SavepastRecords[i].indexValue === index) {
                                        SavepastRecords.splice(i, 1)
                                    }

                                }
                                for (let i in checkboxData) {
                                    if (checkboxData[i] == record.uniqueId)
                                        checkboxData.splice(i, 1)
                                }

                                setCheckBox(false)

                            }
                        }}
                    />
                )
            }
        }
    ];

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content
        };
        return result;
    }


    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    }



    const SavepastRecordOnChange = (row, e) => {
        setRowComplianceId(row.compliance_id)

        // let tempFile = []
        // let temparr = [{
        //     unit_id: Number(unit.unit),
        //     compliance_id: row.compliance_id,
        //     due_date: row.due_date,
        //     completion_date: moment(e && e.target.value).format("d-MMM-YYYY"),
        //     documents: fileupload,
        //     validity_date: null,
        //     pr_completed_by: 5,
        //     amendment_history_id: row.amendment_history_id
        // }]
        if (e.target.name === 'file') {

            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
            // \[ removed to avoid terminal error
            // const fileRegex = /^[a-zA-Z0-9-_& ,.+#()\[-\]{}$^=%@]*$/
            let files = e.target.files
            let file_max_size = 26214400
            // let FILE_TYPE = [
            //     "doc", "docx", "rtf", "pdf", "txt", "png", "jpeg", "gif", "csv", "xls", "xlsx",
            //     "rar", "tar", "gz", "ppt", "pptx", "jpg", "bmp", "odt", "odf", "ods", "xml"
            // ];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var file_name = file.name;
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                if (fileRegex.test(f_name) === false) {
                    return;
                }
                let file_size = file.size;
                if (file_size > file_max_size) {
                    // displayMessage(message.file_maxlimit_exceed);
                    // callback(null);
                    return;

                }
                // var file_extension = file_name.substring(file_name.lastIndexOf('.') + 1).toLowerCase();
                // if (FILE_TYPE.indexOf(file_extension) !== -1) {
                //     // displayMessage(message.invalid_file_format);
                //     // $('#' + fileListener.target.id).val('');
                //     // callback(null);
                //     return;
                // }
                if (file) {
                    convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                        // if (file_content == null) {
                        //     // callback(message.file_content_empty);
                        // }
                        var fN = name.substring(0, name.lastIndexOf('.'));
                        var fE = name.substring(name.lastIndexOf('.') + 1);
                        var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                        var f_Name = fN + '-' + uniqueId + '.' + fE;

                        var result = uploadFileFormat(size, f_Name, file_content);
                        setFileData(result)
                        results.push(result);
                        setFileUpload(results)
                        // if (results.length == files.length) {
                        //     callback(results);
                        // }
                        const filePayload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "UploadComplianceTask_temp_upload",
                                    {
                                        "le_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                        "c_id": cid,
                                        "d_id": Number(domains.domain),
                                        "u_id": Number(unit.unit),
                                        "start_date": moment().format("D-MMM-YYYY"),
                                        "file_info":
                                            results,
                                        "compliance_history_id": Number(row.amendment_history_id)
                                    }
                                ]
                            }
                        ]
                        UploadFile({
                            payload: filePayload,
                            paramid: paramid
                        })
                        setFileUpload(results)
                    });
                }
            }
        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5" style={{ backgroundColor: '' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            )
        })

        // temparr.push({
        //     unit_id: Number(unit.unit),
        //     compliance_id: row.compliance_id,
        //     due_date: row.due_date,
        //     completion_date: moment(e && e.target.value).format("d-MMM-YYYY"),
        //     documents: tempFile,
        //     validity_date: null,
        //     pr_completed_by: 2,
        //     amendment_history_id: row.amendment_history_id
        // })

        // for (let i = 0; i < e.target.files.length; i++) {

        //     tempFile.push({
        //         file_size:e.target.files[i].size,
        //         file_name:e.target.files[i].name,
        //         file_content:"JVBERi0xLjMNCiXi48/TDQoNCjEgMCBvYmoNCjw8D"
        //     })
        //     const filePayload = [
        //         authtoken,
        //         {
        //             "session_token": authtoken,
        //             "request": [
        //                 "MoveComplianceTaskFile",
        //                 {
        //                 "le_id":entityid,
        //                 "c_id":Number(unit.category),
        //                 "d_id":Number(domains.domain),
        //                 "u_id":Number(unit.unit),
        //                 "start_date":moment().format("d-MMM-YYYY"),
        //                 "file_info":
        //                 tempFile,
        //                 "compliance_history_id":133
        //              }

        //             ]

        //         }
        //     ]
        //     UploadFile({
        //         payload: filePayload,
        //         paramid: paramid
        //     })

        // console.log(e.target.files,'e.target.files[i]');
        // console.log(e.target.files[i].size, 'e.target.files[i]');
        // // console.log(formData,'formData')
        // }
        // temparr.push({
        // unit_id: Number(unit.unit),
        // compliance_id: row.compliance_id,
        // due_date: row.due_date,
        // completion_date: moment(e&&e.target.value).format("d-MMM-YYYY"),
        // documents:tempFile,
        // validity_date: null,
        // pr_completed_by: 5,
        // amendment_history_id: row.amendment_history_id


        // temparr.push({
        //     unit_id: Number(unit.unit),
        //     compliance_id: row.compliance_id,
        //     due_date: row.due_date,
        //     completion_date: moment(e && e.target.value).format("d-MMM-YYYY"),
        //     documents: tempFile,
        //     validity_date: null,
        //     pr_completed_by: 5,
        //     amendment_history_id: row.amendment_history_id

        // })

        // setSavePastRecords([...SavepastRecords, ...temparr])


    }
    useEffect(() => {

        // if (list && list.category && list.category.length > 0) {
        //     setCategory(list.category)
        // }
        if (totalCount && batchCount) {
            setCount({
                start_count: totalCount,
                batch_count: batchCount
            })
        }
    }, [totalCount && batchCount])
    useEffect(() => {
        if (statutoryUnitList && statutoryUnitList.statutoryListByUnit) {
            if (show == true) {
                let tempArr = tempList.concat(statutoryUnitList && statutoryUnitList.statutoryListByUnit)
                setTempList(tempArr)
                // setDataList([dataList,statutoryUnitList && statutoryUnitList.statutoryListByUnit])
            }
            else {
                setTempList(statutoryUnitList.statutoryListByUnit)
            }

        }
    }, [statutoryUnitList && statutoryUnitList.statutoryListByUnit, show])

    //  useEffect(() => {


    //      if (dataList) {
    //         let temp=[]
    //         let data=dataList.map((item)=>{
    //                 return item.pr_compliances
    //             })
    //        for(let i in data){
    //            temp.push({
    //             amendment_history_id:data&&data[i].amendment_history_id,
    //             assignee_id: data && data[i].assignee_id,
    //             assignee_name: data && data[i].assignee_name,
    //             compliance_id: data&& data[i].compliance_id,
    //             compliance_name: data&& data[i].compliance_name,
    //             compliance_task_frequency: data&& data[i].compliance_task_frequency,
    //             description: data && data[i].description,
    //             due_date: data && data[i].due_date,
    //             pr_statutory_date: data && data[i].pr_statutory_date,
    //             primary_legislation: data&& data[i].primary_legislation,
    //             secondary_legislation: data && data[i].secondary_legislation,
    //             statutory_provision: data && data[i].statutory_provision,
    //             task_code: data && data[i].task_code,
    //             uniqueId:Math.floor(Math.random() * 100)
    //            })
    //        }
    //     //    setDataList([...dataList,...temp])
    //     console.log(temp,'temp');

    //     }
    // }, [dataList])






    useEffect(() => {
        setDataList(statutoryUnitList && statutoryUnitList.statutoryListByUnit)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (statutoryUnitList && statutoryUnitList.statutoryListByUnit) {
            const ab = statutoryUnitList && statutoryUnitList.statutoryListByUnit.map(function (item) {
                if ((array.indexOf(item.level_1_statutory_name) < 0))
                    array.push(item.level_1_statutory_name)
            })
            array.map((data, i) => {
                // let array2 = [];
                let temp = [];
                let entityArray = [];
                if (data) {
                    statutoryUnitList && statutoryUnitList.statutoryListByUnit.map((child) => {
                        if (data === child.level_1_statutory_name) {
                            child && child.pr_compliances.length && child.pr_compliances.map((item) => {
                                entityArray = {
                                    amendment_history_id: item.amendment_history_id,
                                    assignee_id: item.assignee_id,
                                    assignee_name: item.assignee_name,
                                    compliance_id: item.compliance_id,
                                    compliance_name: item.compliance_name,
                                    compliance_task_frequency: item.compliance_task_frequency,
                                    description: item.description,
                                    due_date: item.due_date,
                                    pr_statutory_date: item.pr_statutory_date,
                                    primary_legislation: item.primary_legislation,
                                    secondary_legislation: item.secondary_legislation,
                                    statutory_provision: item.statutory_provision,
                                    task_code: item.task_code,
                                    uniqueId: Math.floor(Math.random() * 100)
                                }
                                temp.push(entityArray)
                            })

                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            setDataList(finalarray)
        }

    }, [statutoryUnitList && statutoryUnitList.statutoryListByUnit])

    useEffect(() => {
        setDataList(tempList)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tempList) {
            const ab = tempList.map(function (item) {
                if ((array.indexOf(item.level_1_statutory_name) < 0))
                    array.push(item.level_1_statutory_name)
            })
            array.map((data, i) => {
                // let array2 = [];
                let temp = [];
                let entityArray = [];
                if (data) {
                    tempList.map((child) => {
                        if (data === child.level_1_statutory_name) {
                            child && child.pr_compliances.length && child.pr_compliances.map((item) => {
                                entityArray = {
                                    amendment_history_id: item.amendment_history_id,
                                    assignee_id: item.assignee_id,
                                    assignee_name: item.assignee_name,
                                    compliance_id: item.compliance_id,
                                    compliance_name: item.compliance_name,
                                    compliance_task_frequency: item.compliance_task_frequency,
                                    description: item.description,
                                    due_date: item.due_date,
                                    pr_statutory_date: item.pr_statutory_date,
                                    primary_legislation: item.primary_legislation,
                                    secondary_legislation: item.secondary_legislation,
                                    statutory_provision: item.statutory_provision,
                                    task_code: item.task_code,
                                    uniqueId: Math.floor(Math.random() * 100)
                                }
                                temp.push(entityArray)
                            })

                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            setDataList(finalarray)
        }

    }, [tempList])


    useEffect(() => {

        // if (list && list.category && list.category.length > 0) {
        //     setCategory(list.category)
        // }
        if (list && list.unit && list.unit.length > 0) {
            setUnits(list.unit)
        }
    }, [list])



    useEffect(() => {

        if (isAuth) {
            if (entityid !== '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request":
                            [
                                "GetPastRecordsFormData",
                                {
                                    "le_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid
                                }
                            ]

                    }
                ]
                if (entityid != 'null') {
                    getCompletedTask({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
        }
    }, [isAuth, entityid])
    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        if (list && list.act) {
            setAct(Object.values(list.act))

        }
    }, [list.act])
    useEffect(() => {
        Domainvalidator.current.showMessages()
    }, [])
    useEffect(() => {
        if (dataList.length) {
            const count = dataList.map((item, i) => {
                return item.child.length
            })
            const total = count.reduce((a, b) => a + b, 0)
            setEntires(total)
        }



    }, [dataList])
    useEffect(() => {
        const unitdata = units.filter((item) => {
            return item.unit_id === Number(unit.unit)
        })
        setCID(unitdata && unitdata[0] && unitdata[0].country_id)
    }, [unit, units])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-0 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }} >
                                    <span style={{ color: '#1890ff' }}>Completed Task-Current Year</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card rounded p-2">
                            <div className='col-md-12'>
                                <Tabs defaultActiveKey={tab} onChange={onChange} activeKey={tab} type="card">
                                    <TabPane tab="Select Unit & Domain" key="1">
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    {/* <div className="mb-3"> */}
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Legal Entity : </b> <span style={{ "color": "red" }}>*</span></label>
                                                    </div>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Legal Entity"
                                                                onChange={(data, value) => {
                                                                    if (data !== undefined) {
                                                                        setCurrentEntity(data)
                                                                        setUnit({
                                                                            ...unit,
                                                                            legal_entity: data,
                                                                            division: '',
                                                                            category: '',
                                                                            unit: ''
                                                                        })
                                                                        setDomain({
                                                                            domain: ''
                                                                        })
                                                                        setFormSubmit(false)
                                                                        setAddFormSubmit(false)
                                                                    }
                                                                    else {
                                                                        setCurrentEntity("")
                                                                        setUnit({
                                                                            ...unit,
                                                                            legal_entity: '',
                                                                            division: '',
                                                                            category: '',
                                                                            unit: ''
                                                                        })
                                                                        setDomain({
                                                                            domain: ''
                                                                        })
                                                                        setFormSubmit(false)
                                                                        setAddFormSubmit(false)
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                                style={{ width: '100%', marginTop: "5px" }}
                                                            >
                                                                {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legal_entity',
                                                                unit.legal_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                                    }
                                                    {/* </div> */}
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label htmlFor=""><b>Division : </b></label>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={true}
                                                            size="default"
                                                            value={unit.division || undefined}
                                                            // disabled={unit.legal_entity === "" ? true : false}
                                                            placeholder="Enter Division"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setUnit({
                                                                        ...unit,
                                                                        division: data
                                                                    })
                                                                    let CategoryData = _.filter(list && list.category && list.category, { div_id: Number(data) })
                                                                    setCategory(CategoryData)
                                                                    let unitData = _.filter(list && list.unit && list.unit, { division_id: Number(data) })
                                                                    setUnits(unitData)
                                                                }
                                                                else {
                                                                    setUnit({
                                                                        ...unit,
                                                                        division: ''
                                                                    })
                                                                    setCategory([])
                                                                    setUnits([])
                                                                }
                                                            }}

                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {list && list.division && list.division.length && list.division.map((item, i) => {
                                                                return (
                                                                    <Option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label htmlFor=""><b>Category : </b></label>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            disabled={unit.division === "" ? true : false}
                                                            placeholder="Enter Category"
                                                            value={unit.category || undefined}
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setUnit({
                                                                        ...unit,
                                                                        category: data
                                                                    })
                                                                    let unitData = _.filter(list && list.unit && list.unit, { category_id: Number(data) })
                                                                    setUnits(unitData)
                                                                }
                                                                else {
                                                                    setUnit({
                                                                        ...unit,
                                                                        category: ''
                                                                    })
                                                                    setUnits([])
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {category.length && category.map((item, i) => {
                                                                return (
                                                                    <Option key={item.cat_id}>
                                                                        {item.cat_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: '10px' }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    {/* <div className="mb-3"> */}
                                                    <div className='form-group'>
                                                        <label htmlFor=""><b>Unit : </b> <span style={{ "color": "red" }}>*</span></label>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={true}
                                                            value={unit.unit || undefined}
                                                            size="default"
                                                            placeholder="Enter Unit"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setUnit({
                                                                        ...unit,
                                                                        unit: data
                                                                    })
                                                                    setDomain({
                                                                        ...domains,
                                                                        domain: '',
                                                                        frequency: '',
                                                                        acts: '',
                                                                    })
                                                                    setFormSubmit(false)
                                                                }
                                                                else {
                                                                    setUnit({
                                                                        ...unit,
                                                                        unit: ''
                                                                    })
                                                                    setDomain({
                                                                        ...domains,
                                                                        domain: '',
                                                                        frequency: '',
                                                                        acts: '',
                                                                    })
                                                                }

                                                            }}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {units && units.map((item, i) => {
                                                                return (
                                                                    <Option key={item.unit_id}>
                                                                        {item.unit_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            ' Unit',
                                                            unit.unit,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Unit Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label htmlFor=""><b>Domain : </b> <span style={{ "color": "red" }}>*</span></label>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={true}
                                                            size="default"
                                                            value={domains.domain || undefined}
                                                            placeholder="Enter Domain"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setDomain({
                                                                        ...domains,
                                                                        domain: data,
                                                                        frequency: '',
                                                                        acts: '',
                                                                    })
                                                                }
                                                                else {
                                                                    setDomain({
                                                                        ...domains,
                                                                        domain: '',
                                                                        frequency: '',
                                                                        acts: '',
                                                                    })
                                                                }
                                                            }}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {list && list.domain && list.domain.length && list.domain.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'Domain',
                                                            domains.domain,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Domain Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label htmlFor=""><b>Act : </b> </label>
                                                    </div>
                                                    <div className='form-group'>
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Act"
                                                            disabled={domains.domain === "" ? true : false}
                                                            value={domains.acts || undefined}
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setDomain({
                                                                        ...domains,
                                                                        acts: data
                                                                    })
                                                                }
                                                                else {
                                                                    setDomain({
                                                                        ...domains,
                                                                        acts: ''
                                                                    })
                                                                }
                                                            }}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {
                                                                Act && Act.length && Act[0].length && Act[0].map((item, i) => {

                                                                    return (
                                                                        <Option key={item}>
                                                                            {item}
                                                                        </Option>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: '10px' }}>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label htmlFor=""><b>Frequency : </b> <span style={{ "color": "red" }}>*</span></label>
                                                </div>
                                                <div className='form-group'>
                                                    <Select
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        allowClear={true}
                                                        value={domains.frequency || undefined}
                                                        size="default"
                                                        placeholder="Enter Frequency"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                setDomain({
                                                                    ...domains,
                                                                    frequency: data
                                                                })
                                                            }
                                                            else {
                                                                setDomain({
                                                                    ...domains,
                                                                    frequency: ''
                                                                })
                                                            }
                                                        }}
                                                        style={{ width: '95%', marginTop: "5px" }}
                                                    >
                                                        {list && list.frequency && list.frequency.length > 0 && list.frequency.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency}>
                                                                    {item.frequency}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'Frequency',
                                                        domains.frequency,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Frequency Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>

                                    </TabPane>

                                    <TabPane disabled={selectDomain === true ? false : true} tab={<span>Select Compliance</span>} key="3">
                                        {dataList && dataList.length > 0 ? dataList.map((item, i) => {
                                            // let data=dataList.map((item)=>{
                                            //     return item.pr_compliances
                                            // })
                                            // console.log(data,'dataa');
                                            //   var merged = [].concat.apply([], dataList[i].pr_compliances);
                                            //   console.log(item.pr_compliances,'item.pr_compliances');
                                            //   console.log(merged,'manoj');
                                            return (
                                                <Collapse key={i}>
                                                    <Panel header={item.parent} key="1">
                                                        <Table
                                                            size={'small'}
                                                            // className='userprivclass'
                                                            columns={columns}
                                                            className={localStorage.getItem("currentTheme")}
                                                            dataSource={item.child}
                                                            bordered
                                                            fixedHeader={dataTableProperties.fixedHeader}
                                                            scroll={{ x: 1050 }}
                                                            pagination={false} />
                                                    </Panel>
                                                </Collapse>
                                            )
                                        }) : <Card> <lable style={{ marginLeft: '40%', color: "red" }}>No Records Found</lable></Card>}
                                        {IsClosed == false ?
                                            <div className='col-md-12 mt-2'>
                                                {/* <div className='row' style={{ textAlign: 'center', marginLeft: '27%' }}>
                                                    <div className='col-md-6'>
                                                        <Button type="primary" shape="round" className='showbutton' style={{ float: "center", marginLeft: "50px" }}
                                                            size='default' onClick={showMore}>
                                                            Show More <i> <DownOutlined /></i>
                                                        </Button>
                                                    </div>
                                                </div> */}
                                            </div>
                                            : false
                                        }
                                        {dataList && dataList.length > 0 ?
                                            <div className='col-md-12 mt-3'>
                                                <div className='row'>

                                                    <div className='col-md-3' >
                                                        <label>Showing 1 to  <span>{entires}</span>  of {entires} entries</label>
                                                    </div>


                                                </div>
                                            </div>
                                            : ""}

                                    </TabPane>
                                </Tabs>
                                <br />
                            </div>
                            <br />

                            <div className="col-md-12" style={{ marginTop: '-5%' }}>
                                <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                    {tab === '2' || tab === '3' ?
                                        <div>
                                            <Button type="primary" shape="round" className='prevbutton addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginTop: '30px' }}
                                            icon={<ArrowLeftOutlined />} size='default'
                                             onClick={() => {
                                                if (tab === "2") {
                                                    setTab("1")

                                                }
                                                if (tab === "3") {
                                                    setTab("1")
                                                }
                                            }}
                                        >Previous</Button>
                                            {/* <button style={{ float: "left", marginTop: '30px' }} className="usrbutton button--anthe prev" onClick={() => {
                                                if (tab === "2") {
                                                    setTab("1")

                                                }
                                                if (tab === "3") {
                                                    setTab("1")
                                                }
                                            }}><span class="text-white fs-3 fw-bold">Previous</span></button> */}
                                        </div>
                                        : <div className="col-lg-4 col-md-5 d-none d-md-flex"></div>}

                                    {tab === '1' || tab === '2' ?
                                        <div className='text-end'>
                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginTop: '30px' }}
                                                size='default' 
                                                onClick={() => {
                                                    if (tab === "1") {
                                                        setAddFormSubmit(true);
                                                        if (validator.current.allValid()) {
                                                            setSelectUnit(true)
                                                            setTab("3")
                                                        }
                                                    }
                                                    if (tab === "1") {
                                                        setFormSubmit(true);
                                                        if (validator.current.allValid()) {
                                                            if (Domainvalidator.current.allValid()) {

                                                                setTab("3")
                                                                setSelectDomain(true)
                                                                setShow(false)
                                                                const payloads = [
                                                                    authtoken,
                                                                    {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "GetStatutoriesByUnit",
                                                                            {
                                                                                'le_id': localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                                                                'unit_id': Number(unit.unit),
                                                                                'domain_id': Number(domains.domain),
                                                                                'level_1_statutory_name': domains.acts,
                                                                                'compliance_task_frequency': domains.frequency,
                                                                                'start_count': 0,
                                                                                'batch_count': 0
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                                getStatutoriesByUnit({
                                                                    payload: payloads,
                                                                    paramid: paramid
                                                                })
                                                            }
                                                        }
                                                    }
                                                }}
                                            >Next <i> <ArrowRightOutlined /></i></Button>
                                            {/* <button style={{ float: "right" }} className="usrbutton button--anthe"
                                                onClick={() => {
                                                    if (tab === "1") {
                                                        setAddFormSubmit(true);
                                                        if (validator.current.allValid()) {
                                                            setSelectUnit(true)
                                                            setTab("3")
                                                        }
                                                    }
                                                    if (tab === "1") {
                                                        setFormSubmit(true);
                                                        if (validator.current.allValid()) {
                                                            if (Domainvalidator.current.allValid()) {

                                                                setTab("3")
                                                                setSelectDomain(true)
                                                                setShow(false)
                                                                const payloads = [
                                                                    authtoken,
                                                                    {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "GetStatutoriesByUnit",
                                                                            {
                                                                                'le_id': localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                                                                'unit_id': Number(unit.unit),
                                                                                'domain_id': Number(domains.domain),
                                                                                'level_1_statutory_name': domains.acts,
                                                                                'compliance_task_frequency': domains.frequency,
                                                                                'start_count': 0,
                                                                                'batch_count': 0
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                                getStatutoriesByUnit({
                                                                    payload: payloads,
                                                                    paramid: paramid
                                                                })
                                                            }
                                                        }
                                                    }
                                                }}><span class="text-white fs-3 fw-bold">Next</span></button> */}
                                        </div>
                                        : dataList && dataList.length > 0 ? <div style={{ marginRight: '45%', display: 'flex', alignItems: 'end' }}>
                                            <Button type="primary" shape="round" className='addbutton'
                                                style={{ background: "#198754", borderColor: "#198754", marginTop: '30px' }}
                                                icon={<PlayCircleOutlined />} size='default'
                                                onClick={() => {
                                                    setSelectDomain(true)
                                                    const payloads = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "SavePastRecords",
                                                                {
                                                                    "le_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                                                    "pr_compliances_1": SavepastRecords
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                    if (checkbox == true) {
                                                        if (checkboxData.length == date.length) {
                                                            if (date.length > 0) {


                                                                SaveCompletedTask({
                                                                    payload: payloads,
                                                                    paramid: paramid
                                                                })



                                                            }
                                                        }
                                                        else {
                                                            Toaster.error("Task Completion Date / Document Issued Date Required")
                                                        }
                                                    }
                                                    else {
                                                        Toaster.error("Select atleast one completed compliance")

                                                    }

                                                    if (fileupload.length > 0) {


                                                        const filePayload1 = [                                             //     const filePayload = [
                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request": [
                                                                    "MoveComplianceTaskFile",
                                                                    {
                                                                        "le_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                                                        "c_id": Number(cid),
                                                                        "d_id": Number(domains.domain),
                                                                        "u_id": Number(unit.unit),
                                                                        "start_date": moment().format("D-MMM-YYYY"),
                                                                        "file_info":
                                                                            SavepastRecords[0].documents,
                                                                        // fileupload,
                                                                        "compliance_history_id": Number(rowcomplianceid)
                                                                    }


                                                                ]

                                                            }
                                                        ]
                                                        Movetaskfile({
                                                            payload: filePayload1,
                                                            paramid: paramid
                                                        })
                                                    }


                                                }}

                                            >Submit</Button>
                                            {/* <div class="button2" id={localStorage.getItem('currentTheme')} onClick={() => {
                                                setSelectDomain(true)
                                                const payloads = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "SavePastRecords",
                                                            {
                                                                "le_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                                                "pr_compliances_1": SavepastRecords
                                                            }
                                                        ]
                                                    }
                                                ]
                                                if (checkbox == true) {
                                                    if (checkboxData.length == date.length) {
                                                        if (date.length > 0) {


                                                            SaveCompletedTask({
                                                                payload: payloads,
                                                                paramid: paramid
                                                            })



                                                        }
                                                    }
                                                    else {
                                                        Toaster.error("Task Completion Date / Document Issued Date Required")
                                                    }
                                                }
                                                else {
                                                    Toaster.error("Select atleast one completed compliance")

                                                }

                                                if (fileupload.length > 0) {


                                                    const filePayload1 = [                                             //     const filePayload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "MoveComplianceTaskFile",
                                                                {
                                                                    "le_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? unit.legal_entity : entityid,
                                                                    "c_id": Number(cid),
                                                                    "d_id": Number(domains.domain),
                                                                    "u_id": Number(unit.unit),
                                                                    "start_date": moment().format("D-MMM-YYYY"),
                                                                    "file_info":
                                                                        SavepastRecords[0].documents,
                                                                    // fileupload,
                                                                    "compliance_history_id": Number(rowcomplianceid)
                                                                }


                                                            ]

                                                        }
                                                    ]
                                                    Movetaskfile({
                                                        payload: filePayload1,
                                                        paramid: paramid
                                                    })
                                                }


                                            }}
                                            >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                Submit
                                            </div> */}
                                        </div> : false}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}
export default connect(mapStateToProps, {
    getCompletedTask,
    getStatutoriesByUnit,
    SaveCompletedTask,
    UploadFile,
    Movetaskfile
})(CompletedTaskCurrentYear);



/*

<TabPane disabled={selectUnit === true ? false : true} tab={<span>Select Domain</span>} key="2">
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain: </b> <span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Act: </b></label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Frequency: </b><span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={true}
                                                            size="default"
                                                            value={domains.domain || undefined}
                                                            placeholder="Enter Domain"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setDomain({
                                                                        ...domains,
                                                                        domain: data,
                                                                        frequency: '',
                                                                        acts: '',
                                                                    })
                                                                }
                                                                else {
                                                                    setDomain({
                                                                        ...domains,
                                                                        domain: '',
                                                                        frequency: '',
                                                                        acts: '',
                                                                    })
                                                                }
                                                            }}
                                                            // value={filter.seller}
                                                            style={{ width: '100%' }}
                                                        >
                                                            {list && list.domain && list.domain.length && list.domain.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {Domainvalidator.current.message(
                                                            'Domain',
                                                            domains.domain,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${FormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Domain Required',
                                                                }
                                                            })}
                                                    </div>
                                                    <div className='col-md-4'>
                                                        
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Act"
                                                            disabled={domains.domain === "" ? true : false}
                                                            value={domains.acts || undefined}
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setDomain({
                                                                        ...domains,
                                                                        acts: data
                                                                    })
                                                                }
                                                                else {
                                                                    setDomain({
                                                                        ...domains,
                                                                        acts: ''
                                                                    })
                                                                }
                                                            }}
                                                            // value={filter.seller}
                                                            style={{ width: '100%' }}
                                                        >
                                                            {
                                                                Act && Act.length && Act[0].length && Act[0].map((item, i) => {

                                                                    return (
                                                                        <Option key={item}>
                                                                            {item}
                                                                        </Option>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </div>
                                                    <div className='col-md-4'>
                                                       
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={true}
                                                            value={domains.frequency || undefined}
                                                            size="default"
                                                            placeholder="Enter Frequency"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    setDomain({
                                                                        ...domains,
                                                                        frequency: data
                                                                    })
                                                                }
                                                                else {
                                                                    setDomain({
                                                                        ...domains,
                                                                        frequency: ''
                                                                    })
                                                                }
                                                            }}
                                                            // value={filter.seller}
                                                            style={{ width: '100%' }}
                                                        >
                                                            {list && list.frequency && list.frequency.length && list.frequency.map((item, i) => {
                                                                return (
                                                                    <Option key={item.frequency}>
                                                                        {item.frequency}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {Domainvalidator.current.message(
                                                            'Frequency',
                                                            domains.frequency,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${FormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Frequency Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div><br />
                                        </TabPane>

*/