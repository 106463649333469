import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom'
import { Modal, Input, Table, Button, Collapse, Steps, Tooltip, Select, Popover, Card, Progress, Tag, Statistic, Row, Col } from 'antd';
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import { getUnitClosureList, saveUnitClosureData } from '../../Store/Action/Master/UnitClosure'
import { InfoCircleOutlined, PlayCircleOutlined, ExclamationCircleTwoTone, PlusOutlined, SearchOutlined, ArrowUpOutlined, CloseOutlined, ArrowDownOutlined, ExclamationCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, LikeOutlined, DislikeOutlined, StopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { GetRemarksData } from '../../Store/Action/Transcations/Taskrecall'
import SimpleReactValidator from 'simple-react-validator';
import { UNIT_CLOSURE_LIST } from '../../Store/types';
const mapStateToProps = (state) => ({
    unitClosureList: state.UnitClosure.UnitClosure,
    filterrecall: state.recallfilters.recallfilters

});
const UnitClosure = ({
    saveUnitClosureData,
    getUnitClosureList,
    unitClosureList,
    filterrecall: { recallremarks },
    GetRemarksData

}) => {
    console.log(unitClosureList.UnitClosureList, 'dkjhfdkjsfhdjf');
    const { Option } = Select;
    const { Panel } = Collapse;
    const _ = require("lodash");
    const location = useLocation();
    const paramid = location.pathname;
    const formValidator = useRef(new SimpleReactValidator());
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [remarks, setRemarks] = useState('')
    //const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [show, setShow] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [statkey, setStatKey] = useState("0")
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: '',
        selectedtype: ''

    })
    console.log(modalData, 'modalData');
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [modalVisible, setModaVisible] = useState(false)
    const [activedata, setActivedata] = useState('0')
    const [inactivedata, setInactivedata] = useState('0')
    const [closeddata, setCloseddata] = useState('0')

    const [entityid, setCurrentEntity] = useState("");
    useEffect(() => {
        if (((localStorage.getItem('SelectedEntityid') != null) && (localStorage.getItem('SelectedEntityid') != 'null'))) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    const { Search } = Input;

    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText({
        //     ...searchText,
        //     data: selectedKeys[0]
        // });
        // setSearchedColumn(dataIndex);
    };
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText({
            data: '',
            index: searchText.index + 1
        });
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div>
                <Input
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    // color: filtered ? 'red' : undefined,
                    //  color: 'black',
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const tablehandleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const columns = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '50px',
            // ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            width: '150px',
            // ellipsis: true,
            ...getColumnSearchProps('division_name', 'Division'),
            filteredValue: filteredInfo.division_name || null,
            sorter: (a, b) => {
                if (a && a.division_name && a.division_name.length && b && b.division_name && b.division_name.length) {
                    return a.division_name.localeCompare(b.division_name);
                } else if (a && a.division_name && a.division_name.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.division_name && b.division_name.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },//a.division_name && b.division_name != null ? a.division_name.localeCompare(b.division_name) : "z",
            sortOrder: sortedInfo.columnKey === 'division_name' ? sortedInfo.order : null,
            render: (text, record) => {
                return (
                    <Fragment>
                        {text != null ?
                            <Tooltip title={text}>{text}</Tooltip> : '-'}
                    </Fragment>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            // width: '130px',
            // ellipsis: true,
            ...getColumnSearchProps('category_name', 'Category'),
            filteredValue: filteredInfo.category_name || null,
            sorter: (a, b) => {
                if (a && a.category_name && a.category_name.length && b && b.category_name && b.category_name.length) {
                    return a.category_name.localeCompare(b.category_name);
                } else if (a && a.category_name && a.category_name.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.category_name && b.category_name.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },//a.division_name && b.division_name != null ? a.division_name.localeCompare(b.division_name) : "z",
            // sorter: (a, b) => a.category_name && b.category_name != null ? a.category_name.localeCompare(b.category_name) : "",
            sortOrder: sortedInfo.columnKey === 'category_name' ? sortedInfo.order : null,
        },
        {
            title: 'Unit',
            dataIndex: 'unit_code',
            key: 'unit_code',
            // ellipsis: true,
            // align: 'left',
            ...getColumnSearchProps('unit_code', 'Unit'),
            filteredValue: filteredInfo.unit_code || null,
            sorter: (a, b) => a.unit_code.localeCompare(b.unit_code),
            sortOrder: sortedInfo.columnKey === 'unit_code' ? sortedInfo.order : null,
            // width: '200px',
            render: (text, record) => {
                return (
                    <Fragment>
                        <p style={{ whiteSpace: 'unset' }}><span><Tooltip title={`${record.address},${record.postal_code}`}><InfoCircleOutlined style={{ fontSize: "13px", marginRight: "5px", marginTop: "5px" }} /></Tooltip>&nbsp;</span>
                            <Tooltip title={`${text}-${record.unit_name}`}>{text}-{record.unit_name}</Tooltip></p>
                    </Fragment>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            ellipsis: true,
            width: '100px',
            align: 'center',
            filters: [
                {
                    text: 'Active',
                    value: 0,
                },
                {
                    text: 'Inactive',
                    value: 1,
                },
            ],
            filteredValue: filteredInfo.is_active,
            onFilter: (value, record) => record.is_active === value,
            filterSearch: false,
            render: (text, record) => {
                return (
                    <Fragment>
                        {
                            text == 0 ? "Active" : "In-Active"
                        }
                    </Fragment>
                )
            }
        },
        {
            // title: <><label>Actions</label><Tooltip className='serviveproidertooltipclass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"Actions for Close/Closed/Reactive"}><InfoCircleOutlined /></Tooltip></>,
            title: 'Closure',
            dataIndex: 'currentStatus',
            // className: 'actiontd',
            width: '120px',
            align: 'center',
            // width:"100px",
            filters: [
                {
                    text: 'Close',
                    value: 0,
                },
                {
                    text: 'Closed',
                    value: 1,
                },
                {
                    text: 'Reactivate',
                    value: 2,
                },
            ],
            filteredValue: filteredInfo.currentStatus,
            onFilter: (value, record) => record.currentStatus == value,//filterfunction(value, record),
            filterSearch: false,
            render: (text, record) => {
                return (
                    <Fragment>
                        {record.is_active == 0 ?
                            <Button
                                type="primary"
                                style={{ padding: "5px", width: "80px", textAlign: "center" }}
                                onClick={() => {
                                    setShow(true)
                                    closeById(record.unit_id, "closed");
                                }}
                                title=""
                            >Close
                            </Button> : record.is_active == 1 && record.validity_days > 30 ?
                                <span>Closed</span>
                                :
                                <Tooltip title={(30 - record.validity_days) + '-' + 'Days Left'} color='royalblue'>
                                    <Button
                                        type="primary"
                                        style={{ padding: "5px", width: "80px", textAlign: "center" }}
                                        onClick={() => {
                                            setModalData({
                                                password: '',
                                                reason: ''
                                            })
                                            setShow(true)
                                            closeById(record.unit_id, "reactive");
                                        }}
                                        title=""
                                    >Reactivate
                                    </Button>
                                </Tooltip>

                        }
                    </Fragment>
                )
            }
        }
    ];

    const content = (
        <div>
            <Row gutter={16}>
                <Col span={8}>
                    <Card className='unitClosure' bordered={true}>
                        <Statistic
                            title="Active"
                            value={activedata}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                        // suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className='unitClosure' bordered={true}>
                        <Statistic
                            title="In-Active"
                            value={inactivedata}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                        // suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className='unitClosure' bordered={true}>
                        <Statistic
                            title="Closed"
                            value={closeddata}
                            precision={2}
                            valueStyle={{ color: '#eed202' }}
                            prefix={<CloseOutlined />}
                        // suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                setRemarks(text)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    const OnSubmited = () => {
        setAddFormSubmit(true)

        if (formValidator.current.allValid()) {
            // setModaVisible(false)
            saveUnitClosureData({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveUnitClosureData",
                                {
                                    "password": modalData.password,
                                    "closed_remarks": remarks,
                                    "unit_id": Number(modalData.unit_id),
                                    "grp_mode": modalData.reason == 'closed' ? "close" : "reactive",
                                    "legal_entity_id": entityid
                                }
                            ]
                        }
                    ],
                paramid: paramid,
                entityid: entityid,
                type: modalData.selectedtype
            })
            setModalData({
                ...modalData,
                password: ""
            })
            setRemarks("")
            setAddFormSubmit(false)
        }
    }

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])
    const passwordcancel = () => {
        setModaVisible(false)
        setModalData({
            ...modalData,
            password: ""
        })
        setRemarks("")
        setAddFormSubmit(false)
    }
    const handleOk = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };

    const handleCancel = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    useEffect(() => {
        if (isAuth) {
            if (entityid != '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetUnitClosureUnitData",
                            {
                                "legal_entity_id": entityid,

                            }
                        ]
                    }
                ]
                if (entityid != "null") {
                    getUnitClosureList({
                        payload: payload,
                        paramid: paramid
                    })
                } else {
                    setBaseData('')
                }
            }

        }
    }, [isAuth, entityid])

    const closeById = (unit_id, type) => {
        type == "closed" ?
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to Close?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setModalData({
                        ...modalData,
                        unit_id: unit_id,
                        reason: type,
                        selectedtype: 'closed'
                    })
                }
            }) :
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to Activate?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setModalData({
                        ...modalData,
                        unit_id: unit_id,
                        reason: type,
                        selectedtype: 'activate'
                    })
                }
            })
    };

    useEffect(() => {
        if (modalData.legal_entity == '' || null || undefined) {
            setModalData({
                ...modalData,
                legal_entity: entityid
            })

        }
    }, [modalData.legal_entity])


    const [baseData, setBaseData] = useState([]);
    console.log(baseData, 'baseData');
    const dispatch = useDispatch()
    useEffect(() => {
        let entityArray = []
        let tempArraryNew = []
        if (unitClosureList && unitClosureList.UnitClosureList && unitClosureList.UnitClosureList.length > 0) {
            let array1 = _.filter(unitClosureList.UnitClosureList, { is_active: 0 });
            setActivedata(array1.length)
            let array2 = _.filter(unitClosureList.UnitClosureList, { is_active: 1 });
            setInactivedata(array2.length)

        }
        if (unitClosureList && unitClosureList.UnitClosureList && unitClosureList.UnitClosureList.length > 0) {
            unitClosureList.UnitClosureList.map((subList) => {
                let currentStatus = ''
                if (subList.is_active == 0) {
                    currentStatus = 0 //close
                } else if (subList.is_active == 1 && subList.validity_days <= 30) {
                    currentStatus = 2 //reactivate
                } else if (subList.is_active == 1 && subList.validity_days > 30) {
                    currentStatus = 1 // closed
                    let tempclosed = Number(closeddata) + 1
                    setCloseddata(tempclosed)
                } else {
                    currentStatus = subList.is_active //default is_active
                }
                entityArray = {
                    'address': subList.address,
                    'business_group_name': subList.business_group_name,
                    'category_name': subList.category_name,
                    'closed_on': subList.closed_on,
                    'division_name': subList.division_name,
                    'is_active': subList.is_active,
                    'legal_entity_id': subList.legal_entity_id,
                    'legal_entity_name': subList.legal_entity_name,
                    'postal_code': subList.postal_code,
                    'unit_code': subList.unit_code,
                    'unit_id': subList.unit_id,
                    'unit_name': subList.unit_name,
                    'validity_days': subList.validity_days,
                    'currentStatus': currentStatus
                }
                tempArraryNew.push(entityArray)
            })
            //setBaseData(unitClosureList.UnitClosureList);
            setBaseData(tempArraryNew)
        }
    }, [unitClosureList])

    useEffect(() => {
        return () => {
            dispatch({
                type: UNIT_CLOSURE_LIST,
                payload: ['', { unit_closure_units: [] }]
            })
        }
    }, [])


    const searchRecords = (e) => {
        if (e == 'clear') {
            setClearText('')
            setBaseData(unitClosureList.UnitClosureList)
            setIconEnable(false)
        } else {
            setClearText(e.target.value)
            if (e.target.value.length > 0) {
                setIconEnable(true)
            } else {
                setIconEnable(false)
            }
            const filterTable = unitClosureList.UnitClosureList.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
            setBaseData(filterTable)
        }

    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                                <li className="breadcrumb-item">
                                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    <span style={{ color: '#1890ff' }}>Master</span>
                                                </li>
                                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                    <span style={{ color: '#1890ff' }}>Unit Closure </span>
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6 d-flex align-items-center">
                                    <label htmlFor="" className='me-2'><b>Legal Entity:  </b></label>
                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                        <div  >
                                            <Select
                                                allowClear={false}
                                                size="default"
                                                style={{ width: '300px' }}
                                                placeholder="Select Legal Entity"
                                                onChange={(data, value) => {
                                                    if (data == undefined) {
                                                        setCurrentEntity("")
                                                    }
                                                    else {
                                                        setCurrentEntity(data)
                                                        setBaseData([])
                                                        setSortedInfo({})
                                                        setFilteredInfo({})
                                                        setCloseddata('0')
                                                        setSearchText({
                                                            data: '',
                                                            index: 0
                                                        })
                                                    }
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                    return (
                                                        <Option key={item.le_id}>
                                                            {item.le_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div> :
                                        <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>}
                                </div>
                                <div className="col-6">
                                    <div className="search-box1" style={{ position: 'relative', marginRight: '0', float: 'right' }}>
                                        <input className="search-txt" value={clearText} type="text" autoComplete='off' onChange={searchRecords} placeholder="Type to Search" />
                                        <a className="search-btn1">
                                            {iconEnable == true ?
                                                <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                                : <i className="fas fa-search"></i>}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card-body p-0 pt-2">
                                    <Table
                                        key={baseData}
                                        id={'unitclosuretableview'}
                                        sortDirections={["ascend", "descend", "ascend"]}
                                        columns={columns}
                                        className={'tablecolorchanges ' + localStorage.getItem("currentTheme")}
                                        size={'small'}
                                        dataSource={baseData}
                                        onChange={tablehandleChange}
                                        bordered
                                        pagination={false}
                                        scroll={{ x: 1000 }}
                                        showSorterTooltip={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal visible={modalVisible} footer={null} className='usr_modal_class'
                    onCancel={passwordcancel}>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                            <input
                                type="password"
                                id="password"
                                name='password'
                                className="form-control"
                                autoComplete='off'
                                placeholder="Enter password"
                                value={modalData.password}
                                onChange={(e) => {
                                    setModalData({
                                        ...modalData,
                                        password: e.target.value
                                    })
                                }} />
                            {formValidator.current.message(
                                'password',
                                modalData.password,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required',
                                    }
                                })}
                        </div>
                    </div>
                    <br />
                    <div className="col-md-12">
                        <label htmlFor=""><b>Reason</b> <span style={{ "color": "red" }}>*</span></label>
                        <div className='d-flex align-items-end'>

                            <textarea className='form-control'
                                placeholder="Enter Reason"
                                onChange={(e) => {
                                    setRemarks(e.target.value)
                                }}
                                value={remarks}
                                
                            ></textarea>

                            <span className="text-info ms-1 d-flex 
                                align-items-center justify-content-center" style={{ marginTop: '30px' }} onClick={() => { showRemarksModal() }}>
                                <PlusCircleOutlined />
                            </span>
                        </div>
                        {formValidator.current.message(
                            'reason',
                            remarks,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Reason Required',
                                }
                            })}

                    </div>
                    <br />

                    <div className="form-actions popupbtncolour text-center">
                        <Button type="primary" shape="round" className={'addbutton '+ localStorage.getItem('currentTheme')}
                            style={{ background: "#198754", borderColor: "#198754", marginLeft: '-4%' }}
                            icon={<PlayCircleOutlined />} size='default'
                            onClick={OnSubmited}
                        >
                            Submit
                        </Button>
                        {/* <div className="button2" id={localStorage.getItem('currentTheme')} onClick={OnSubmited}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            Submit
                        </div> */}
                    </div>

                </Modal>
                <Modal title="Remarks List" visible={remarksModal}
                    onOk={handleOk}
                    onCancel={handleCancel} className={"remark-header add-service-prv " + localStorage.getItem('currentTheme')} footer={false}>
                    <Table
                        // className='userprivclass'
                        size={'small'}
                        columns={RemarksColumn}
                        dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                        bordered
                        pagination={{
                            pageSizeOptions: ['10', '30', '50'],
                            defaultPageSize: dataTableProperties.pagesize,
                            showSizeChanger: dataTableProperties.sizechanger
                        }}
                    />
                </Modal>
            </div>
        </Fragment >
    )
}
export default connect(mapStateToProps, {
    getUnitClosureList,
    GetRemarksData,
    saveUnitClosureData
})(UnitClosure);